<template>
  <div id="app">
    <div v-if="false" id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <headerM 
    :logo="confData && confData.main_logo ? confData.main_logo : null" 
    :smallLogo="confData && confData.login_logo ? confData.login_logo : null"/>
    <router-view/>
    <!-- doraditi -->
    <div v-if="footer" v-html="footer"></div>
    <goTop v-if="showGoToTop" style="position: fixed; bottom: 10px; right:70px;" />
  </div>
</template>

<script>
import headerM from '@/components/header/header'
import goTop from '@/components/goTop.vue'
import { api } from '@/api'
export default {
  name:'App',
  components: {
    headerM,
    goTop
  },
  data () {
    return {
      showGoToTop: false,
      confData: null,
      footer: null,
      no: ''
    }
  },
  created(){
    this.setConfig()
    //  console.log('window.location.href')
    // console.log(window.location.href) // returns the href (URL) of the current page
    // console.log(window.location.hostname) // returns the domain name of the web host
    // console.log(window.location.pathname) // returns the path and filename of the current page
    // console.log(window.location.protocol) // returns the web protocol used (http: or https:)
    
  },
  mounted(){
    // hide reCaptcha:
    
    this.$recaptchaLoaded().then(() => {
      const recaptcha = this.$recaptchaInstance
      // console.log('recaptcha')
      // console.log(recaptcha)
      recaptcha.siteKey = this.no
      // console.log(recaptcha)
      recaptcha.hideBadge()
    })
   

    this.checkSession()
    setInterval(this.checkSession, 200000)
    this.handleToTopButton();
    window.addEventListener("resize", this.handleToTopButton);
    window.addEventListener("scroll", this.handleToTopButton);
  },
  methods:{
    setConfig () {
      localStorage.removeItem('clapColor');
      let r = document.querySelector(':root');
      //  console.log(r)
      // r.style.setProperty('--main-color','#062931');
      //   //  r.style.setProperty('--btn-color','linear-gradient(102.82deg, #33C36D 20.16%, #23C07E 80.73%)') 
      // r.style.setProperty('--btn-color','#2EC271')
      // r.style.setProperty('--btn-color-transparent', 'rgba(51, 195, 109, 0.2)')
      //  localStorage.setItem('clapColor','#2EC271')
      //  localStorage.setItem('bgColor','#232325')

      api.getSiteConfigurations().then(res => {
        // console.log(res)
        this.confData= res.data.data
        localStorage.setItem('config', JSON.stringify(this.confData))
        this.footer = this.confData['site_footer']
        localStorage.setItem('bgColor',this.confData['--main-background-color'])
        localStorage.setItem('clapColor',this.confData['--btn-color'])
        this.no = this.confData.recaptcha_key_client
        
      
        r.style.setProperty('--main-background-color',this.confData['--main-background-color']);
        r.style.setProperty('--header-background-color',this.confData['--header-background-color']);
        r.style.setProperty('--login-modal-background-color',this.confData['--login-modal-background-color']);
        r.style.setProperty('--comments-background-color',this.confData['--comments-background-color']);
        r.style.setProperty('--btn-color',this.confData['--btn-color']);
        r.style.setProperty('--title-color',this.confData['--title-color']);
        r.style.setProperty('--text-color',this.confData['--text-color']);
        r.style.setProperty('--text-soft-color',this.confData['--text-soft-color']); 
        r.style.setProperty('--border-color',this.confData['--border-color']);
        r.style.setProperty('--header-text-color',this.confData['--header-text-color']); 
        
      }).catch(() =>{
        // console.log(e)
      })
    },
     checkSession () {
      api.checkSession().then(res => {
        //  console.log(res)
         this.$store.commit('changeLoged', true)
         localStorage.setItem('sid', res.data.data.sid)
        // this.$store.commit('setUserName', res.data.name)
         this.$store.commit('setUserPrivilege', res.data.data.user_type)
         this.getUserData()
        // console.log('gotov check session');
      }).catch(() => {
        localStorage.removeItem('sid')
        this.$store.commit('changeLoged', false)
        localStorage.removeItem('userName')
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)

        // if(this.$route.name !== "blog" 
        //    && this.$route.name !== 'singleBlog'
        //   ){
        //   this.$router.push({ name: 'blog' })
        // }
        if(this.$route.name === "admin" ){
          this.$router.push({ name: 'blog' })
        }
      })
    },
    getUserData() {
            let params = {
                sid : localStorage.getItem('sid'),
                id : localStorage.getItem('usr_id')
            }
            api.getUserData(params).then(res => {
                this.profileInfo = res.data.data
                // console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(() => {
            //  console.log(e)
            })
    },
    handleToTopButton() {
      // console.log('hhyhhyhshy go top' + this.showGoToTop)
      var body = document.body;
      var html = document.documentElement;
      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      if (height > 1500 && document.documentElement.scrollTop > 550) {
        this.showGoToTop = true;
      } else {
        this.showGoToTop = false;
      }
    },
  },
  watch: {
     '$i18n.locale': {
      handler (newLocale, oldLocale) {
        if (newLocale !== oldLocale) {
            // console.log('this.$route.params.'+JSON.stringify(this.$route.params))
          if (this.$route.params && this.$route.params.lang !== undefined ) { // this.$route.params.hasOwnProperty('lang') Object.hasOwnProperty.call(this.$route.params,"lang")
            this.$router.replace({ params: { lang: this.$i18n.locale } })
          } else {
            this.$router.push({ name: 'blog', params: { lang: this.$i18n.locale } })
          }
        }
      }
    },
      // footer(){
      // },
    footer(){
          // console.log("Data handler invoked - watcher")
          this.$nextTick(()=>{
            // eslint-disable-next-line
            if(MARS_BLOG_AFTERINIT){
              // eslint-disable-next-line
              MARS_BLOG_AFTERINIT()
            }
          })
      }
  }
 
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  margin:0;
}
#app {
  //  font-family: Avenir, Helvetica, Arial, sans-serif;
  //  font-family: "Oswald", sans-serif;
   font-family: 'Archivo';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: var(--text-color);
  background: var(--main-background-color);
}

.nested-enter-active, .nested-leave-active {
	transition: all 0.9s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.65s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--text-color);

    &.router-link-exact-active {
      color: var(--text-color);
    }
  }
}
</style>
