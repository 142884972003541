<template>
  <transition  name="slide-fade">
  <div v-if="subComment" class="subComment">
    <div class="line"></div>
    <div class="wrappData">
      <div class="wrappMaindata">
        <div class="name">{{comment.usr_name}}</div>
        <div class="date">{{formatDate(comment.com_created)}}</div>
      </div>
      <div>
        {{comment.com_content}}
      </div>
    </div>
  </div>
</transition>
</template>

<script>
export default {
    name: 'mainComment',
    props: ['comment'],
    components:{
   
    },
    data(){
        return{
          subComment: false
        }
    },
    mounted(){
      setTimeout(() => {
        this.subComment= true
      }, 300);
    },
    methods: {
      formatDate(d) {
        if(!d){
          return null
        }
        let format = new Date(d).toISOString().split('T')[0]
            return format.split("-").reverse().join(".");
      },
    }

}
</script>

<style lang='scss' scoped>
.subComment{
       border-radius: 5px;
       box-sizing: border-box;
       width:100%;
       padding: 10px;
       padding-left: 0;
       padding-right: 17px;
       display: flex;
       justify-content: flex-start;
       align-items: center;

       .line{
        box-sizing: border-box;
        width: 50px;
        height: 0;
        border-top: 1px solid var(--comments-background-color);
       }
       .wrappData{
        width: calc(100% - 50px);
        padding-left: 22px;
       }
       .wrappMaindata{
         display: flex;
         justify-content: space-between;
          // margin-bottom: 10px;
          font-weight: bold;

          .name{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: var(--text-color);
          }
          .date{
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            letter-spacing: 0.04em;
            color: var(--text-soft-color);

          }
       }
}

</style>