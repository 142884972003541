<template>
    <div class="wrappIcon" @click="send">
        <div v-if="type === 'confirm'" class="confirm">
            <img 
            :src="
            production ?
            path_prefix.slice(0, -1).concat(require('../assets/images/icons/confirm.svg')) : 
            require('../assets/images/icons/confirm.svg')" alt="" >

        </div>
        <div v-else-if="type === 'delete'" class="delete">
            <img 
            :src="
            production ?
            path_prefix.slice(0, -1).concat(require('../assets/images/icons/delete.svg')) : 
            require('../assets/images/icons/delete.svg')
            " alt="" class="src">
        </div>
        <div v-else class="undo">
            <span>undo</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'confirmIcon',
    props: ['type'],
    data(){
        return{
            production: false,
            path_prefix: null
        }
    },
    mounted () {
       this.production = process.env.NODE_ENV === 'production'
       this.path_prefix = window.__blog_path_prefix
    },
    methods:{
        send() {
            this.$emit('set')
        }
    }

}
</script>
<style lang="scss" scoped>
.wrappIcon {
    // transform: scale(0.3);
    div{
        margin-left: 5px;
    }


}

</style>