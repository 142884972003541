var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config"},[_c('h2',[_vm._v("Configuration")]),_vm._l((_vm.config),function(con,index){return _c('div',{key:index,staticClass:"wrapp"},[_c('div',{staticClass:"div"},[( con.id !== 'login_logo' 
                && con.id !== 'main_logo'
                && con.id !== 'site_banner' 
                && con.id !== 'site_footer'
                && con.id !== 'custom_js'
                && con.id !== 'favicon'
                && con.id !== 'blog_not_found'
                && con.id !== 'default_thumb'
                && con.id !== 'page_not_found'
                && con.id.startsWith('--')
              )?_c('div',{staticClass:"wrapInput"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(con.id))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config[index].value),expression:"config[index].value"}],staticClass:"inputPicker",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.config[index].value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config[index], "value", $event.target.value)}}}),_c('div',{staticClass:"selectPicker",style:({background: _vm.config[index].value})},[_c('div',{staticClass:"fill",on:{"click":function($event){return _vm.managePicker(index)}}}),(_vm.showPicker === index)?_c('photoshopPicker',{staticClass:"picker",on:{"ok":_vm.ok,"cancel":function($event){return _vm.cancelPicker(index)},"input":function($event){return _vm.updateValue($event, index)}},model:{value:(_vm.config[index].value),callback:function ($$v) {_vm.$set(_vm.config[index], "value", $$v)},expression:"config[index].value"}}):_vm._e()],1),_c('info',{attrs:{"explanation":con.explanation}})],1):( con.id !== 'login_logo' 
                && con.id !== 'main_logo'
                && con.id !== 'site_banner' 
                && con.id !== 'site_footer'
                && con.id !== 'custom_js'
                && con.id !== 'favicon'
                && con.id !== 'blog_not_found'
                && con.id !== 'default_thumb'
                && con.id !== 'page_not_found'
              )?_c('div',{staticClass:"wrapInput"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(con.id))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config[index].value),expression:"config[index].value"}],attrs:{"type":"text"},domProps:{"value":(_vm.config[index].value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config[index], "value", $event.target.value)}}}),_c('info',{attrs:{"explanation":con.explanation}})],1):( con.id === 'site_banner'
                || con.id === 'site_footer' 
                || con.id === 'custom_js')?_c('div',{staticClass:"wrapInput"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(con.id))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.config[index].value),expression:"config[index].value"}],attrs:{"type":"text"},domProps:{"value":(_vm.config[index].value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config[index], "value", $event.target.value)}}}),_c('info',{attrs:{"explanation":con.explanation}})],1):_vm._e(),(con.id === 'main_logo')?_c('div',{staticClass:"image wrapInput"},[_c('addImg',{attrs:{"con":con},on:{"setimg":_vm.set_main_logo}}),_c('info',{attrs:{"explanation":con.explanation}})],1):(con.id === 'login_logo')?_c('div',{staticClass:"image wrapInput"},[_c('addImg',{attrs:{"con":con},on:{"setimg":_vm.set_login_logo}}),_c('info',{attrs:{"explanation":con.explanation}})],1):(con.id === 'favicon')?_c('div',{staticClass:"image wrapInput"},[_c('addImg',{attrs:{"con":con},on:{"setimg":_vm.set_favicon}}),_c('info',{attrs:{"explanation":con.explanation}})],1):(con.id === 'blog_not_found')?_c('div',{staticClass:"image wrapInput"},[_c('addImg',{attrs:{"con":con},on:{"setimg":_vm.set_blog_not_found}}),_c('info',{attrs:{"explanation":con.explanation}})],1):(con.id === 'default_thumb')?_c('div',{staticClass:"image wrapInput"},[_c('addImg',{attrs:{"con":con},on:{"setimg":_vm.set_default_thumb}}),_c('info',{attrs:{"explanation":con.explanation}})],1):(con.id === 'page_not_found')?_c('div',{staticClass:"image wrapInput"},[_c('addImg',{attrs:{"con":con},on:{"setimg":_vm.set_page_not_found}}),_c('info',{attrs:{"explanation":con.explanation}})],1):_vm._e()])])}),_c('div',{staticClass:"wrappButton"},[_c('div',{staticClass:"buttonCancel",on:{"click":_vm.reset}},[_vm._v("reset")]),_c('div',{staticClass:"buttonSend",on:{"click":_vm.send}},[_vm._v("send")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }