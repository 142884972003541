export default {
  formatDate(rowDate, summaryDate) { // 1param - date from base ;  2param boolean 
    if (!rowDate) {
      return null
    }
    let date = rowDate.substring(0, 10).split('-')
    let month = parseInt(date[1])
    let monthText = ''
    switch (month) {
      case 1:
        monthText = 'January'
        break;
      case 2:
        monthText = 'February'
        break;
      case 3:
        monthText = 'March'
        break;
      case 4:
        monthText = 'April'
        break;
      case 5:
        monthText = 'May'
        break;
      case 6:
        monthText = 'June'
        break;
      case 7:
        monthText = 'July'
        break;
      case 8:
        monthText = 'August'
        break;
      case 9:
        monthText = 'September'
        break;
      case 10:
        monthText = 'October'
        break;
      case 11:
        monthText = 'November'
        break;
      case 12:
        monthText = 'December'
        break;
      default: ''
    }
    // let month = new Date(this.blogData.date).toLocaleString('default', { month: 'long' });
    // return monthText + ' ' + date[2] + ', ' + date[0]
    if (!summaryDate)
      return monthText + ' ' + date[2] + ', ' + date[0]
    else
      return date[2] + ' ' + monthText.substring(0, 3) + ' ' + date[0]
  }

}
