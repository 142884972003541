<template>
    <div class="modalShare">
        <div class="exit" @click="$emit('closeshowmodal')">
            <img :src="
                production ?
                    path_prefix.slice(0, -1).concat(require('../../assets/images/closeIcon.svg')) :
                    require('../../assets/images/closeIcon.svg')">
        </div>
        <div class="contentShare">
            <div class="txt">
                <p class="title">Share</p>
                <p v-if="false" class="description">Get to knon {{ author }} article {{ setTitle(titleP) }}</p>
                <p v-if="!copyM" class="description">{{ setTitle(titleP) }}</p>
                <p v-if="!copyM" class="link" @click="copyTestingCode"><a>{{ setTxtUrl(url1, currentUrl) }} </a></p>
                <div v-if="copyM" class="errormsg">
                    <img :src="
                        production ?
                            path_prefix.slice(0, -1).concat(require('../../assets/images/shareT.svg')) :
                            require('../../assets/images/shareT.svg')
                    ">
                    <span> The link has been successfully <br class="sBr">copied to your clipboard.</span>
                </div>
            </div>
            <div class="shareIcon">
                <!--  :url=" obj.network==='email'||obj.network==='linkedin'|| obj.network==='twitter' ? 'Check this at: ' + url1 + currentUrl : url + currentUrl" -->
                <ShareNetwork v-for="(obj, index) in list" :key="index"
                    :url="obj.network === 'email' ? 'Check this at: ' + url1 + currentUrl : url1 + currentUrl"
                    :title='titleP ? titleP : title'
                    :description="descriptionP ? ('\n ' + descriptionP + ' \n \n' + url1 + ' \n \n') : ('\n ' + description + ' \n \n' + url + ' \n \n')"
                    :quote="url" :hashtags="isblog ? hashtagsblog : hashtags" :network="obj.network" class="spanImg"><a
                        class="aLink" href="#">
                        <img :src="
                            production ?
                                path_prefix.slice(0, -1).concat(obj.img) :
                                obj.img" alt="/">
                        <p>{{ obj.txt }}</p>
                    </a>
                </ShareNetwork>
                <!--a :href="'fb-messenger://share/?'+ url1   + currentUrl" > Send In Messenger</a>   
                --><!-- ShareNetwork
                v-for="(obj , index) in list"
                :key="index"
                :url=" obj.network==='email' ? 'Check this at: ' + url1 + currentUrl : url + currentUrl"
                :title='titleP'
                :description=" '\n '+ descriptionP +' \n \n'+ url + ' \n \n'"
                :quote="url"
                :hashtags="hashtags"
                :network="obj.network"
                class="spanImg"    
            ><a class="aLink" href="#" >
                <img  :src="obj.img" alt="/">
                <p>{{obj.txt}}</p>
            </a>
            </ShareNetwork -->
                <div v-for=" index in 0" :key="index + Math.random()" class="spanImg">
                    <a class="aLink" href="#">
                        <div class="img"></div>
                        <p>Another</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import facebookImg from '@/assets/images/Face.svg'
import twitterImg from '@/assets/images/twitter.svg'
import emailImg from '@/assets/images/email1.sh.svg'
import linkedinImg from '@/assets/images/likkedin.sh.svg'
import messengerImg from '@/assets/images/tumbir.sh.svg'
import reeditImg from '@/assets/images/redit.sh.svg'
import pintarestImg from '@/assets/images/pintarest.sh.svg'
import tumbirImg from '@/assets/images/essenger.sh.svg'
import { baseURL } from '@/api'
export default {
    props: ['titleP', 'descriptionP', 'author', 'isblog'],
    data() {
        return {
            title: 'Blog post',
            description: "",
            url: "https://link.co.nz/",
            url1: "https://link.co.nz",
            hashtags: "blog",
            hashtagsblog: "blog",

            list: [
                { network: 'facebook', img: facebookImg, txt: 'Facebook' },
                { network: 'linkedin', img: linkedinImg, txt: 'Linked in' },
                { network: 'twitter', img: twitterImg, txt: 'Twitter' },
                { network: 'email', img: emailImg, txt: 'Email' },
                { network: 'messenger', img: messengerImg, txt: 'Messenger' },
                { network: 'reddit', img: reeditImg, txt: 'Reddit' },
                { network: 'pinterest', img: pintarestImg, txt: 'Pinterest' },
                { network: 'tumblr', img: tumbirImg, txt: 'Tumblr' }


            ],
            copyM: false,
            production: false,
            path_prefix: null

        }
    },
    created() {
        this.currentUrl = this.$route.path
        //console.log(this.baseURL.slice(0,this.baseURL.length-4))
        this.setUrl()
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix
    },
    methods: {
        copyTestingCode() {
            // console.log('copyTestingCode')
            this.copyM = true
            this.$clipboard(this.url1 + this.currentUrl)
            setTimeout(() => {
                this.copyM = false
            }, 2000);
        },
        setTitle(txt) {
            return txt.slice(0, 60);
        },
        setTxtUrl(url, currentUrl) {
            return (url + currentUrl).slice(0, 57).concat('...')
        },
        setShareUrl(slash) {
            // if(slash)
            //     return this.baseURL.slice(0,this.baseURL.length-5)
            // else 
            //      return this.baseURL.slice(0,this.baseURL.length-4)
            if (slash)
                return this.baseURL //.concat('/')
            else
                return this.baseURL
        },
        setUrl() {
            //  this.url= 'https:'+this.setShareUrl(false) // "https://test.co.nz/"
            //  this.url1= 'https:'+this.setShareUrl(true) //"https://test.co.nz"
            this.url = this.setShareUrl(false) // "https://test.co.nz/"
            this.url1 = this.setShareUrl(true) //"https://test.co.nz"

        }
    },
    computed: {
        baseURL() {
            if (process.env.NODE_ENV !== 'production') {
                return 'http:' + baseURL.replace('/api/', '')
            }
            return window.location.protocol + '//' + window.location.host //+ window.__blog_path_prefix.slice(0, -1)
        }
    }

}
</script>

<style lang="scss" scoped>
.modalShare {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    background: #000000e1;
    // z-index:1000 !important;
    z-index: 211 !important;
    display: flex;
    justify-content: center;

    // align-items: center;
    .exit {
        // position: absolute;
        top: 0px;
        right: 0px;
        margin-top: 60px;
        margin-right: 59px;
        position: absolute;

        img {
            width: 22px;
        }
    }

    .contentShare {
        // background: rgba(231, 236, 237, 0.555);
        // padding-top: 190px; 
        margin-top: 100px;
        padding-top: 90px;
        width: 624px;
        min-height: 469px;

        // overflow: auto;
        .txt {
            text-align: center;
            // padding-bottom: 25px;
            // position: relative;
            // height: 230px;
            // padding-bottom: 60px;
            // height: initial;
            height: 230px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title {
                text-align: center;
                font-style: normal;
                font-weight: normal;
                font-size: 80px;
                line-height: 80px;
                color: #FFFFFF;
                margin-top: 0px;
                margin-bottom: 35px;
            }

            .description {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 0;
                margin-bottom: 10px;
                margin-right: 30px;
                margin-left: 30px;
            }

            .link {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #C4C4C4;
                margin-top: 0;
                text-decoration: none;
                margin-right: 30px;
                margin-left: 30px;

                a {
                    color: #C4C4C4;
                    text-decoration: none;
                    cursor: grab;
                }

                a:active {
                    color: #C4C4C4;
                    text-decoration: none;
                    cursor: grabbing;
                }
            }

            .errormsg {
                width: 513px;
                max-width: 100%;
                height: 56px;
                background: #000000;
                /* text 80 */

                border: 1px solid #CFD8DB;
                box-sizing: border-box;

                border-radius: 2px;

                display: flex;
                justify-content: center;
                align-items: center;

                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin: 0 auto;

                // margin-bottom: 30px;
                img {
                    margin-right: 10px;
                }

            }

        }

        .shareIcon {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            justify-content: center;
            align-items: center;

            .spanImg {
                margin: 0 15px;
                width: 79px;
                text-decoration: none !important;
                text-align: center;
                // margin-bottom: 51px;
                margin-bottom: 33px; // only the space between twitter and icon below is different 32px

                .aLink {
                    height: 80px !important;
                    text-decoration: none;
                }

                .img {
                    background: #C4C4C4;
                    width: 79px;
                    height: 79px;
                    border-radius: 50%;

                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 24px;
                    text-align: center;
                    color: #E7ECED;
                    text-decoration: none !important;
                    margin: 5px 0;
                }
            }
        }
    }
    .sBr {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .modalShare {
        .contentShare {
            // background: rgba(231, 236, 237, 0.555);
            // padding-top: 95px;
            padding-top: 25px;
            margin-top: 70px;
            box-sizing: border-box;
            width: 100%;
            height: initial;
            overflow: auto;
            .txt {
                // padding-bottom: 32px;
                height: 210px;
                .title {
                    font-size: 60px;
                    line-height: 80px;
                    color: #FFFFFF;
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
                .errormsg {
                    width: initial;
                    height: initial;
                    padding: 28px 33px;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: initial !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    margin: 0 auto;

                    // margin-bottom: 30px;
                    img {
                        height: fit-content;
                        margin-top: 6px;
                        margin-right: 10px;
                        margin-left: 10px;
                    }

                }

            }

        }

        .sBr {
            display: initial;
        }
    }

}

@media only screen and (max-width: 450px) {
    .modalShare {
        .exit {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-top: 40px;
            margin-right: 39px;

            img {
                width: 15px;
            }
        }
        .contentShare {
            // background: rgba(231, 236, 237, 0.555);
            // padding-top: 95px;
            padding-top: 25px;
            margin-top: 70px;
            box-sizing: border-box;
            width: 100%;
            height: initial;
            overflow: auto;
            .txt {
                text-align: center;
                //  padding-bottom: 0px;
                // height: initial;
                // min-height: 230px;
                .title {
                    font-size: 60px;
                    line-height: 80px;
                    color: #FFFFFF;
                    margin-top: 0px;
                    // margin-bottom: initial;
                }
                .description {
                    font-size: 15px;
                    line-height: 24px;
                    padding-top: 10px;
                    margin-top: 0;
                    margin-bottom: 10px;
                    margin-right: 30px;
                    margin-left: 30px;
                }
                .link {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    margin-top: 0;
                    text-decoration: none;
                    margin-right: 30px;
                    margin-left: 30px;
                    margin-bottom: 0px;

                    a {
                        color: #C4C4C4;
                        text-decoration: none;
                    }
                }
            }
            .shareIcon {
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                width: 80%;
                justify-content: center;
                align-items: center;

                .spanImg {
                    width: fit-content;
                    text-decoration: none !important;
                    text-align: center;
                    margin-bottom: 22px !important;
                    margin-left: 0;
                    margin-right: 15px;

                    .aLink {
                        width: 60px;
                        height: 60px;
                        text-decoration: none;
                    }

                    .img {
                        background: #C4C4C4;
                        width: 79px;
                        height: 79px;
                        border-radius: 50%;

                    }

                    p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 13px;
                        line-height: 24px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }

                .spanImg:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 351px) {
    .modalShare {
        .contentShare {
            .shareIcon {
                .spanImg:nth-of-type(3n) {
                    margin-right: 15px;
                }
            }
        }
    }
}</style>