<template>
  <transition  name="slide-fade">
    <div v-if="showMainComm" class="comment">
    <div class="mainComment">
      <div class="wrappMaindata">
      <div class="name">{{comment.usr_name}}</div>
      <div class="date">{{formatDate(comment.com_created)}}</div>
      </div>
      <div>
        {{comment.com_content}}
      </div>
    </div>
      <div class="more">
        
          <div class="option" @click="leaveComment = !leaveComment">{{$t('comment.reply')}}</div>
  <!-- 
          <div class="wrappSubComm" v-if="!subCommentsRead">
            <subComment  v-if="subComments.length > 0"  :comment="subComments[0]"/>
          </div> -->
          <div class="wrappSubComm" >
            <subComment v-for="(sub, index) in subComments" :key="index" :comment="sub"/>
          </div>
          
          <div class="option2">
            <div class="line"></div>
            <div class="option" v-if="subCommentsRead" @click="getBlogComent">{{$t('comment.seeMore')}}</div>
            <div class="option" v-else-if="offset > 0" @click="seeLess">{{$t('comment.seeLess')}}</div>
          </div>

      </div>
      <div class="more more2">
        <transition name="bounce">
         <wrappLeaveComment :com_id="comment.com_id" :blog="blog" v-if="leaveComment" />
        </transition>
        </div>
        
    </div>
  </transition>
</template>

<script>
import wrappLeaveComment from './wrappLeaveComment.vue'
import subComment from './subComment.vue'
import { api } from '@/api'
export default {
    name: 'mainComment',
    props: ['comment', 'blog'],
    components:{
      wrappLeaveComment,
      subComment
    },
    data(){
        return{
          showMainComm: false,
          leaveComment: false,
          subCommentsRead: true,
          subComments:[
            // { name: 'Sima', comment: `
            // Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galle..of the printing and typesetting industry.
            // Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galle..of the printing and typesetting industry.
            // `, date:"10.10.2020"},
            // { name: 'Ana', comment: 'is simply dummy text of the printing and typesetting industry..industrys standard dummy text ever since the 1500s, when an unknown printer took a galley', date:"10.10.2020"},
            // { name: 'Sima', comment: ' Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galle..of the printing and typesetting industry.', date:"10.10.2020"},
            // { name: 'Ana', comment: 'is simply dummy text of the printing and typesetting industry..industrys standard dummy text ever since the 1500s, when an unknown printer took a galley', date:"10.10.2020"}
          
          ],
          offset: 0,
          count: 1,
        }
    },
    mounted() {
      setTimeout(() => {
        this.showMainComm = true
      }, 300);
      this.getBlogComent()
      this.count = 2
    },
    methods: {
      formatDate(d) {
        if(!d){
          return null
        }
        let format = new Date(d).toISOString().split('T')[0]
            return format.split("-").reverse().join(".");
      },
      getBlogComent() {
        let params= {
          count: this.count,
          offset: this.offset,
          com_id: this.comment.com_id
        }
        api.getBlogComent(params).then(res => {
          // console.log(res.data.data)
          this.subComments.push(...res.data.data)
          this.offset = this.subComments.length
          if(res.data.count <= this.subComments.length) {
            this.subCommentsRead = false
          }
        }).catch(e => {
          console.log(e)
        })
      },
      showMore() {

      },
      seeLess() {
        this.count = 2
        this.offset = 0
        this.subComments = []
        this.subCommentsRead = true

      }
      

    }

}
</script>

<style lang='scss' scoped>
.mainComment{
        //  max-width:100%;
        //  width: fit-content;
        width:100%;
        min-height:100px;
        padding: 10px;
        box-sizing: border-box;
        background-color: var(--comments-background-color);
        // background: #ECECEC;
        border-radius: 25px;
        padding: 21px 17px;
        transition-duration: height .3s;

       .wrappMaindata{
          display: flex;
          justify-content: space-between;
          margin-bottom: 3px;
          font-weight: bold;

          .name{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: var(--title-color);
          }
          .date{
            margin-left: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            letter-spacing: 0.04em;
            color: var(--text-soft-color);

          }
       }

      }
       .more{
        margin-left: 13px;
        border-left: 1px solid var(--comments-background-color);
        background-color: var(--main-background-color);
        

        .option{
          padding-left: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: var(--text-lighter);
          margin-bottom: 5px;
          cursor: pointer;
        }
        .option2{
          display: flex;
          // justify-content: center;
          align-items: flex-end;
          margin-bottom: 20px;
          .option{
            margin-bottom: 0;
            padding-left: 22px;
          }
          .line{
            box-sizing: border-box;
            width: 50px;
            height: 0;
            border-top: 1px solid var(--comments-background-color);
          }

        }
        .option:first-of-type{
          margin-bottom: 2px;
        }
         .wrappSubComm{
            display: flex;
            flex-direction: column;
           transition: height .3s;
            // padding-left: 50px;
            //  justify-content: flex-end;
            //  align-items: flex-end;
         }
       }
       .more2{
        border-left: 1px solid transparent;
        background-color: var(--main-background-color);
        // height: 237px ;
        // transition: height .3s;
        overflow: hidden;
        // 50px->line, 22px->padding from line to content, 13px->margin of more 
        margin-left: calc(50px + 22px + 13px);
       }


</style>
<style>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  /* 50% {
    transform: scale(0.5);
  } */
  100% {
    transform: scale(1);
  }
}
</style>