<template lang="html">
    <div @mouseleave="show = false" v-click-outside="onClickOutside" class="picker-wrapper" :class="{blackDiv:blackDiv}">
      <div class="picker">
        <div v-if="selected" @click="show = !show" class="height-wrapper">
          <div  class="picker-flag" >
            <img class="flag"  
            :src="
            production ? 
            path_prefix.slice(0, -1).concat(getFlag(selected)):
            getFlag(selected)
            " alt="">
          </div>
          <div class="picker-title darkcolor">
            <span>
              {{selected}}
            </span>
          </div>
          <img 
                class="dropdown-arrow" 
                :class="{ open: show }"
                :src="production ? 
                  path_prefix.slice(0, -1).concat(getArrow):
                  getArrow"
                alt="dropdown arrow"
            >
           </div>
        <div v-else @click="show = !show" class="height-wrapper">
            <div class="picker-title darkcolor" >
              {{$t('admin.create.setBlogLocale')}}
            </div> 
            <img 
                class="dropdown-arrow" 
                :class="{ open: show }"
                :src="production ? 
                  path_prefix.slice(0, -1).concat(getArrow):
                  getArrow"
                alt="dropdown arrow"
            >

        </div>
       </div> <!--end picker -->

      <div v-if="show" class="dropdown-wrapper">
        <div v-if="removeString" class="dropdown">
          <div @click="changeLocale(locale)" v-for="locale in removeArrFromArr($i18n.availableLocales, removeString )" :key="locale" class="locale-option">
            <div class="picker-flag">
              <!-- <flag :squared="false" :iso="getFlag(locale)" /> -->
              <img class="flag" 
              :src="production ? 
                path_prefix.slice(0, -1).concat(getFlag(locale)): 
                getFlag(locale)" 
                alt="">
            </div>
            <div class="picker-title">
              {{locale}}
            </div>
          </div>
        </div>
        <div v-else class="dropdown">
          <div @click="changeLocale(locale)" v-for="locale in $i18n.availableLocales" :key="locale" class="locale-option">
            <div class="picker-flag">
              <!-- <flag :squared="false" :iso="getFlag(locale)" /> -->
              <img class="flag" 
              :src="production ? 
                path_prefix.slice(0, -1).concat(getFlag(locale)): 
                getFlag(locale)" alt="">
            </div>
            <div class="picker-title">
              {{locale}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LocalePicker',
    mounted () {
        if(!this.removeString)
            this.selected = this.$i18n.locale
        console.log(this.$i18n.availableLocales)
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix

    },
    props: {
      blackDiv: {
        type: Boolean,
        default: false
      },
      removeString: {
        
      }
     
    },
    data () {
      return {
        show: false,
        selected: false,
        production: false,
        path_prefix: null
      }
    },
    computed: {
      getFlag () {
        return locale => {
          switch (locale) {
            case 'sr':
              return require('@/assets/images/icons/rs.svg')
            case 'en':
              return require('@/assets/images/icons/gb.svg')
            case 'de':
              return require('@/assets/images/icons/de.svg')
            default:
              return require('@/assets/images/icons/rs.svg')
          }
        }
      },
      getArrow() {
        return require('../assets/images/icons/dropdownArrowBig.png')
      }
    },
    methods: {
      onClickOutside () {
        this.show = false
      },
      changeLocale (locale) {
          this.selected = locale;
          this.$emit('setlocal', locale)
       this.show = false
      },
      removeObjectWithId(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
        arr.splice(objWithIdIndex, 1);
        return arr;
     },
     removeStringFromArr(arr, str) {
        const newArray = arr.filter(item => item !== str)
        return newArray;
     },
     removeArrFromArr(arr, arrRem) {
        let res = arr.filter( function( el ) {
            return !arrRem.includes( el );
        } );
        return res
     }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .picker-wrapper {
      // background-color: var(--green-color);
    height: 100%;
    display: flex;
    position: relative;
  
    .flag {
      height: 19px;
      width: 26px;
      margin-right: 5px;
    }
  
    .dropdown-wrapper {
      //   background-color: var(--green-color);
      padding: 20px;
      padding-top: 40px;
      padding-right: 0px;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 3;
    }
    .dropdown {
      background-color: white;
      padding: 10px;
      right: 0px;
      top: 100%;
      // border: 1px solid #ddd;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
  
      .locale-option {
        display: flex;
        align-items: center;
        cursor: pointer;
  
        .picker-title {
          // margin-top: 5px;
           margin-right: 0px;
           color: var(--green-color);
        }
      }
      .locale-option:hover .picker-title {
              color: var(--green-color);
      //   color: $text-hover-color;
      }
    }
  }
  .picker {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    .height-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .picker-flag span {
    border-radius: 3px;
    margin-right: 5px;
  }
  .selectTxt{
    width: fit-content;
    color: var(--green-text);
  }
  .darkcolor{
    color: var(--green-text);
  }
  .picker-title {
    margin-right: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    transition-duration: 0.2s;
    color: var(--green-text);
    width: fit-content;
  }
  .picker:hover .picker-title {
  //   color: $text-hover-color;
  }
  .small-nav-wrapper {
    .picker-wrapper {
      margin-bottom: 200px;
    }
    .dropdown-wrapper {
      padding: 0px;
      position: absolute;
      top: 50px;
      left: 0px;
      z-index: 3;
      width: 85px;
    }
    .dropdown-arrow {
    //   filter: brightness(5);
    transition-duration: 0.3s;
    max-width: 10px;
    }
    img {
        transition-duration: 0.3s;
      }
      img.open,
      .dropdown-arrow.open {
        transform: rotate(180deg);
      }
     
    .height-wrapper .picker-title {
    //   color: white;
      color: var(--green-text);
    }
  }
  .picker-wrapper.blackDiv {
    margin-bottom: 0 !important;
    min-height: 120px !important;
    // background-color: #3f3f41;
    // color: #ffffff;
    color: var(--green-text);
    .dropdown-wrapper{
      top: 30px;
      left: 0px;
    }
     .dropdown {
      background-color: white;
      background-color: #3f3f41;
  
      padding: 0px;
      right: 0px;
      top: 10%;
      border: transparent;
      .picker-title{
         color: #ffffff !important;
      }
     }
  
  }
  </style>
  