export default {
    setBlogCategoryInString(category) {
        let stringSet = ''
        if (category !== undefined && category !== null && category.length > 0) {
            for (let i = 0; i < category.length; i++) {
                console.log(category[i].cat_name)
                if (category[i].cat_name !== null) {
                    if (i === category.length - 1)
                        stringSet += category[i].cat_name
                    else
                        stringSet += category[i].cat_name + ', '
                }
            }
        }
        return stringSet
    }
}