<template lang="html"> 
  <!-- <router-link v-if="$route.name !== 'admin'"  :to="{ path: blogData.link, params: { lang: $i18n.locale } }" > -->
  <router-link v-if="$route.name !== 'admin'"  :to="{ name: 'singleBlog', params: { lang: $i18n.locale, id: blogData.link.slice(6)} }" >
  <!-- <router-link v-if="$route.name !== 'admin'"  :to="{ name: 'singleBlog', params: { lang: this.$i18n.locale, id: blogData.link }}" > -->
<!-- {{ blogData.link.slice(6) }} -->
    <div class="blog-item-wrapper">
      <div class="aspect-wrapper">
        <div class="aspWrap">
          <div class="aspect">
            <div class="img-wrapper">
              <img v-if="blogData.image" :src="setLink(blogData.image)" :alt="blogData.img_alt ? blogData.img_alt : 'main-blog-image'">
              <img  v-else-if="default_thumb" 
                :src="setLink(default_thumb)" />
                <img  v-else 
                    :src="
                      production ? 
                      path_prefix.slice(0, -1).concat(require('../../assets/images/defaultThumb.png')):
                      require('../../assets/images/defaultThumb.png')
                      "
                />
            </div>
          </div>
        </div>
      </div>
      <div class="blog-item-description">
        <div class="title">{{blogData.title}}</div>
        <div class="date">{{setDate}}</div>
        <div class="description" v-if="blogData.description">
          {{ blogData.description.length > 150 ? blogData.description.substring(0, 150) + '...' : blogData.description }}
          <p class="readMore">{{$t('main.readMore')}}</p>
        </div>
      </div>
    </div>
  </router-link>
  <div v-else @click="openBlog()" class="blog-item-wrapper">
    <div class="aspect-wrapper">
      <div class="aspWrap">
        <div class="aspect">
          <div class="img-wrapper">
            <img v-if="blogData.image" :src="setLink(blogData.image)" :alt="blogData.img_alt ? blogData.img_alt : 'main-blog-image'">
          </div>
        </div>
      </div>
    </div>
    <div class="blog-item-description">
      <div class="title">{{blogData.title}}</div>
      <div class="date">{{setDate}}</div>
      <div class="description" v-if="blogData.description">
        {{ blogData.description.length > 150 ? blogData.description.substring(0, 150) + '...' : blogData.description }}
        <p class="readMore"> {{$t('main.readMore')}} </p>
      </div>
      <p>{{ $t('main.status') }}: <span class="status">{{ blogData.status}}</span></p>
    </div>
  </div>
</template>

<script>
import { baseURL, baseURLimage } from '@/api'
import helperDate from '@/js/helperDate.js'
export default {
  name: 'blogItem',
  props: {
    blogData: {
      required: true,
      type: Object
    },
    deleteOnClick: {
      required: false,
      default: false
    }
  },
  mounted(){
    if(this.blogData.date) {
      this.setDate = helperDate.formatDate(this.blogData.date)
    }
    this.production = process.env.NODE_ENV === 'production'
    this.path_prefix = window.__blog_path_prefix

    let config = JSON.parse( localStorage.getItem('config'))
    this.default_thumb = Object.hasOwnProperty.call(config,'default_thumb') ? config.default_thumb : null
   
  },
  data(){
    return{
      setDate: null,
      production: false,
      path_prefix: null,
      default_thumb: null
    }
  },
  computed: {
    baseURL () {
      return baseURL
    }
  },
  methods: {
    setLink(link){
      // return baseURL.replace('/blog-template/api/', link)
      // return baseURLimage + link
      if(this.production){
          if(link.startsWith('/')){
            return link
          } else {
            return '/'+link
          }
        }
     return baseURLimage + link
    },
    openBlog () {
      if (this.deleteOnClick) {
        this.$emit('blogClick')
        return
      }
      console.log('bkjhcfjyskjcbhfnjxwhecfjx-------------'+this.blogData.link)
      // this.$router.push(this.blogData.link)
      this.$router.push({ path:this.$i18n.locale+'/'+this.blogData.link, params: { lang: this.$i18n.locale, id: this.blogData.link } })
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.blog-item-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 842px; 
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 35px;
  padding-top: 50px;
  align-items: flex-start;
  cursor: pointer;

  .aspect-wrapper {
    width: 43%;
    margin-right: 25px;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 5px;
    .aspWrap{
      overflow: hidden;
      border-radius: 5px;
      will-change: transform;
      position: relative;
      z-index: 100;
      // background: var(--comments-background-color);
    }
    .aspect {
      // padding-top: 75%;
      padding-top: 63%;
      position: relative;
      height: 0;

      .img-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        will-change: transform;
        background: var(--comments-background-color);;

        img {
          z-index: 5;
          height: 100%;
          object-fit: cover;
          width: auto;
          border-radius: 5px;
          transition: 1s ease-in-out;
          -webkit-backface-visibility: hidden;
          -ms-transform: translateZ(0); /* IE 9 */
          -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
          transform: translateZ(0);
          -webkit-font-smoothing: antialised;
          backface-visibility: hidden;
          -webkit-font-smoothing: subpixel-antialiased;
          filter: blur(0);
        }
      }

      img:hover {
        // height: 115%;
        // widows: 115%;
         transform: scale(1.1);
      }
    }
  }

  .blog-item-description {
    padding-right: 10px;
    width: calc( 57% - 25px );
    padding-top: 10px;
    .title {
      // font-size: 25px;
      font-size: 24px;
      font-weight: 500;
      word-wrap: break-word;
      height:fit-content;
      // line-height: 30px;
      // line-height: 22px;
      color: var(--title-color);
      margin-bottom: 5px;
      transition-duration: .3s;

    }
    .title:hover {
      color: var(--text-color);
    }
    .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: var(--text-soft-color);
      margin-bottom: 16px;
    }
    .description {
      font-size: 16px;
      line-height: 24px;
      color: var(--text-color);

      span, .readMore {
        margin: 0;
        color: var(--btn-color);
      }
       span:hover,
      .readMore:hover {
        // color: var(--btn-hover);
        filter: brightness(0.90);
        // filter: drop-shadow(0px 1px 3px #DFDFE8);
      }
    }
     .status {
        margin: 0;
        color: var(--err-color);
      }
      .status:hover {
        // color: var(--btn-hover);
        filter: brightness(0.90);
        // filter: drop-shadow(0px 1px 3px #DFDFE8);
      }
  }
}
a:last-child .blog-item-wrapper {
  border-bottom: none;
  margin-bottom: 80px;
}
.blog-item-wrapper.last {
  border-bottom: none;
}
@media only screen and (max-width: 720px){
  .blog-item-wrapper {
    .aspect-wrapper {
      width: 50%;
      height: auto;
    }
    .blog-item-description {
      width: calc( 50% - 25px );
    }
  }
}
@media only screen and (max-width: 450px){
  .blog-item-wrapper {
    flex-direction: column;

    .aspect-wrapper {
      width: 100%;
      height: auto;
    }
    .blog-item-description {
      width: 100%;
    }
  }
}
</style>
