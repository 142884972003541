<template lang="html">
  <div class="delete-blogs-wrapper">
    <search @search="search" :reset="resetSearch"/>
    <blogItem :class="{ last : index === blogs.length - 1 }" @blogClick="deleteBlog(blog)" v-for="(blog, index) in blogs" :key="index" :deleteOnClick="true" :blogData="blog" />
    <div class="show-more-wrapper">
      <div v-if="more" @click="showMore()" class="show-more">
       {{ $t('main.showMore') }}
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import blogItem from '@/components/blog/blogItem.vue'
import search from '../inputcomponents/search.vue'
export default {
  name: 'deleteBlog',
  components: {
    blogItem,
    search
  },
  data () {
    return {
      blogs: [],
      offset: 0,
      count: 6,
      more: true,
      resetSearch: false
    }
  },
  activated () {
    this.getBlogs()
    this.resetSearch = !this.resetSearch
  },
  methods: {
    search(e) {
      // console.log(e)
      let params = {
        title: e
      }
      this.getBlogs(params)

    },
    getBlogs (search) {
      // let params = {
      //   count: this.count,
      //   offset: this.offset,
      //   locale: this.$i18n.locale
      // }
      let params = {}

      if(search) {
        if(Object.hasOwnProperty.call(search, 'title')) {
          params.search = search.title
          this.offset = 0
          this.blogs = []
        }
        if(Object.hasOwnProperty.call(search, 'locale')) {
          params.search = search.locale
          this.offset = 0
          this.blogs= []
        }
      }
      api.getAdminBlogs(params).then(res => {
        this.blogs = res.data.data
        if (this.blogs.length === res.data.count) {
          this.more = false
        }
      }).catch(e => {
         console.log(e);
      })
    },
    deleteBlog (blog) {
      let confirm = window.confirm('Are you sure you want to delete this blog?')
      if (confirm) {
        let params = {
          id: blog.id
        }
        api.deleteBlog(params).then(() => {
          this.getBlogs()
          alert('Blog deleted successfuly')
        }).catch(() => {
          alert('Error deleting blog')
        })
      }
    },
    showMore () {
      let currOffset = this.blogs.length
      let params = {
        count: this.count,
        offset: currOffset,
        locale: this.$i18n.locale
      }
      api.getAdminBlogs(params).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.blogs.push(res.data.data[i])
        }
        if (this.blogs.length === res.data.count) {
          this.more = false
        }
      }).catch(e => {
         console.log(e);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-blogs-wrapper {
  .show-more-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
  }
}
</style>
