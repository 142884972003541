<template>
  <div class="enterEmail">
    <div class="title">{{$t('forgot.forgotPass')}}?</div>
    <div class="txt">
       {{$t('forgot.forgotTxt')}}
    </div>
    <input v-model="email" :class="emailErr? 'basic inputErr':'basic'"  :placeholder="$t('log.email')" type="text"  > 
    <div class="sendButton" @click="checkForm">{{$t('forgot.send')}}</div>
    <p class="errMsg">{{$t(msg)}}</p>
  </div>
</template>

<script>
import helper from '@/js/helperMail'
import { api } from '@/api'

export default {
    name:'enterEmail',
    data(){
        return{
            email:'',
            emailErr: null,
            msg:null
        }
    },
    mounted() {
        window.scrollTo({top: 0, behavior: 'smooth'});

    },
    methods:{
        async checkForm(){
            this.emailErr = null
             this.msg =''

            if(this.email === '' || this.email.trim().length == 0 || !helper.matchemail(this.email)){
                this.emailErr = true
                this.msg = "msg.validEmail"
                return
            }else {
                let params={
                    // privremeno
                    // token:"03AGdBq25Kyhh9pHZD3t6IFrJ005WIPhHwaNMOTXGbUrOjjEFOaLbSmnslc2LTNwgn4gq72Du7VgAy9qUoys7Ez9edQNbxLNBEKbu6sK88ADkxDCFXkWjlmZ1OWTnxjBUlosv-kMSoReeZxBTQhlpOre3M4M5lVNoChN68LopgPmuDG4G_KsEouCI9tsggJJjeauS4wAaAvj0OfqXZzpZBXnr9DHpYwI0irru-h129t_D5SLxggmTASFU7YOkWR4CCSHkoEMeeIADH8MLn-YUWsxaITziQCo3y1DtE-e_etaph2yLW0p5xZqmYwQRUztmrP8Ed4Wxoew7xkLJXsOgtslkI_dXGYVOVgUeMsqqfaveQentIRpRDiRhGlAqZxgO3eCA4l1aYsc1x149Ul0QZZkBooEyM-sPgzs5tYUCQPEZ8n8mJfZJUwkrrmP7iqvT6k5d2sSVudZ_QPRO7n53jvqAi0jn_ucg-rw",
                    // token:token,
                    email:this.email
                }

                let chechReCaptcha = JSON.parse(localStorage.getItem('config'))
                if(Object.hasOwnProperty.call(chechReCaptcha, 'recaptcha_key_client')
                && chechReCaptcha.recaptcha_key_client !== null) {
                    await this.$recaptchaLoaded()
                    const token = await this.$recaptcha('forgot_password').catch(() => {})
                    //   console.log(token)
                    params.token = token
                }

                api.forgot(params).then(() => {
                    this.$emit('checkstep', 'successShow')
                }).catch(e =>{
                    console.log(e)
                    this.$emit('checkstep', 'successShow')
                })
            }
         }
    }

}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
    .enterEmail{
        width:406px;
        text-align: center;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        .title{
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: center;

            color: var(--title-color);
        }
        .txt {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--text-color);
            margin-top: 44px;
            margin-bottom: 24px;
        }
        .sendButton {
            cursor: pointer;
            // margin-top: 30px;
            margin-top: 15px;
            width: 366px;
            height: 50px;
            border-radius: 55px;

            background: var(--btn-color);
            border-radius: 55px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            /* White */

            color: var(--main-background-color);

        }
        .sendButton:hover{
            filter: brightness(.90);
        }

    }
@media only screen and (max-width: 500px){
    .enterEmail{
        max-width:335px;
        margin:0 20px;
        .title{
            font-size: 28px;
            line-height: 30px;
        }
        .txt {
            font-size: 15px;
            line-height: 24px;
            margin-top: 39px;
            margin-bottom: 22px;
        }
        .sendButton {
            // margin-top: 30px;
            margin-top: 15px;
            width: 100%;
            height: 50px;
            border-radius: 55px;
            box-sizing: border-box;
            font-size: 15px;
            line-height: 24px;

        }

    }
    
}

</style>