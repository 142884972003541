<template>
    <div class="singinComponent">
        <div class="wrapp_top">
            <div v-if="logo" class="logo">
                <img :src="setUrl(logo)" />
                <!-- <img src="../../assets/images/logo.svg" alt=""> -->
            </div>
            <div class="loginTitle">{{$t('sign.signUp')}}</div>
        </div>        
         <!-- <div v-if="false" v-facebook-login-button="appId" class="buttonSocial">
            <span>{{$t('log.continueWith')}} Facebook</span>
            <span ><img 
                :src="require(widthPage?'@/assets/images/fsingin.svg':'@/assets/images/fsinginBright.svg')" />
             </span>
        </div>
        
        <div  v-google-signin-button="clientId" class="buttonSocial">
            <span>{{$t('log.continueWith')}} Google</span>
            <span>
                <img :src="require(widthPage? '@/assets/images/gsingin.svg':'@/assets/images/gsinginBright.svg')" />
            </span>
        </div>

        <div class="or">
            <div class="line"></div>
            {{$t('log.or')}}
            <div class="line"></div>
        </div> -->
        <!-- autocomplete="off" not working so chrome add password and email only in first field -->
        <input type="password" style="opacity: 0;position: absolute;">
        <input type="email" style="opacity: 0;position: absolute;">

        <input v-model="user.usr_name" :class="nameErr? 'basic inputErr':'basic '"  :placeholder="$t('sign.name')" type="text" @keyup.enter="focusEmail" autofocus>
        <input v-model="user.usr_email" :class="emailErr? 'basic inputErr':'basic'"  :placeholder="$t('log.email')" type="text" ref="usr_email" @keyup.enter="focusPass"  >
        <input v-model="user.usr_password" :class="passwordErr? 'basic inputErr':'basic'" :placeholder="$t('log.password')" ref="usr_pass" @keyup.enter="focusRePass" type="password" >
        <input v-model="user.reTypePassword" :class="passwordErr? 'basic  inputErr':'basic'" :placeholder="$t('sign.reTypePass')" ref="usr_repass" @keyup.enter="checkForm" type="password" >
        
        <!-- <rember :remember="user.remember" @rememberSend="user.remember=!user.remember" /> -->
        
        <div class="buttonSend" @click="checkForm" >{{$t('sign.signUp')}}</div>
        <!-- <div class="haveaccount">{{$t('sign.accountHave')}} <router-link :to="{ name: 'login', params: { lang: $i18n.locale } }" class="orange" >{{$t('log.logIn')}}</router-link></div> -->
        <p v-if="msg" class="errMsg">{{$t(msg)}}</p><p v-if="eermsg" class="errMsg">{{$t(eermsg)}}</p>
        <div v-if="false" class="terms" >By clicking the button you agree to our <router-link to="/terms" class="bold">Terms and Privacy policy.</router-link></div>
    </div>
</template>

<script>
// import rember from './rememberMe.vue'
import helper from '@/js/helperMail.js'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import FacebookLoginButton from 'vue-facebook-login-button-directive'

import { api, baseURLimage } from '@/api'
export default {
    name: 'singinComponent',
    directives: {
        GoogleSignInButton,
        FacebookLoginButton
    },
    props: {
        eermsg: {
            require: false,
            type: String
        }
    },
    components: {
        // rember
    },
    data () {
        return {
            user:{
                usr_name: '',
                usr_email: '',
                usr_password: '',
                reTypePassword:'',
                remember: false,
            },
            nameErr: false,
            emailErr: false,
            passwordErr: false,
            msg: '',
            widthPage: null,
            // id app google
            clientId: '306827492643-at00dqjbklkflbi52fnd1ckrkthpahjj.apps.googleusercontent.com' ,
            // fb
            appId: 3821175311320178,
            logo: null
        
        }
    },
     created(){
        window.addEventListener("resize", this.resize)
        this.resize()
        // get logo
        let config = JSON.parse( localStorage.getItem('config'))
        this.logo = Object.hasOwnProperty.call(config,'login_logo') ? config.login_logo : null
    },
    methods: {
        // FACEBOOK
         OnFacebookAuthSuccess(idToken) {
              const params={
                token:idToken
            }
            api.loginFacebook(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="msg.wentWrong"
                console.log(e.message);
            })
        },
        OnFacebookAuthFail(error) {
            console.log(error)
            this.msg="msg.wentWrong"
        },
        // end facebook

         // GOOGLE
        OnGoogleAuthSuccess (idToken) {
            const params={
                token:idToken
            }
            api.loginGoogle(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="msg.wentWrong"
                console.log(e.message);
            })
            
        },
        OnGoogleAuthFail (error) {
            if(error && error.error == 'popup_closed_by_user')
                this.msg= "msg.popupClosed"
            else
                this.msg="msg.wentWrong"
        },
        // end google
         getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                 console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        },
        checkForm(){

            let error = false
            this.clearErr()

            if(this.user.usr_name === '' || this.user.usr_name.trim().length == 0  ){
                error= true
                 this.nameErr = true
                 this.msg= "msg.nameRequired"
            }
            if(this.user.usr_email === '' || this.user.usr_email.trim().length == 0 || !helper.matchemail(this.user.usr_email) ){
                error= true
                this.emailErr = true
                this.msg= this.msg.length===0 ? "msg.validEmail": "msg.fieldsEnteredCorrectly"
            }
            if(this.user.usr_password === '' || this.user.usr_password.length <= 6  ){
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "msg.pass7char": "msg.fieldsEnteredCorrectly"
            }else if(this.user.usr_password !== this.user.reTypePassword) {
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "msg.passMatch": "msg.fieldsEnteredCorrectly"
            }
            if(error) {
                return
            }else {
                this.$emit('signin', this.user)
                console.log('SIGNIN---')
            }

        },
        clearErr () {
            this.nameErr = false
            this.emailErr = false
            this.passwordErr = false
            this.msg = ''
        },
        clearData () {
            this.user.usr_name = ''
            this.user.usr_email = ''
            this.user.usr_password = ''
            this.user.reTypePassword= ''
        },
        resize () {
            if (window.innerWidth <= 768) {
                this.widthPage = false
            } else {
                this.widthPage = true
            }
        },
        focusEmail () {
            this.$refs.usr_email.focus()
        },
        focusPass () {
            this.$refs.usr_pass.focus()
        },
        focusRePass () {
            this.$refs.usr_repass.focus()
        },
        setUrl(link) {
            // return baseURL.replace('/blog-template/api/', link)
            return baseURLimage + link
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }
}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
.singinComponent{
        //  background-color:rgba(48, 219, 241, 0.609);
        // width: 406px;
        width: 384px;
        height: fit-content;
        background-color: var(--login-modal-background-color);
        padding: 72px 47px;
        box-sizing: border-box;
        .wrapp_top{
        display: flex;
        flex-direction: column;
        .logo{
            margin: auto;
        }
        .loginTitle {
            font-style: normal;
            font-weight: 700;
            font-size: 23px;
            line-height: 20px;
            text-align: center;
            color: var(--title-color);
            white-space: nowrap;
            margin-top: 13px;
            margin-bottom: 57px;
        }
    }
        .terms{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 25px;
            /* identical to box height, or 208% */


            /* Gray 3 text */

            color: var(text-color);
            .bold{
               color: var(text-color);
               font-weight: bold; 
               text-decoration: none;
            }

        }
       
    }
@media only screen and (max-width: 500px){
    .singinComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 334px;
        // height: 711px;
        // margin-bottom: 71px!important;
        height: fit-content;
        

        .singinTitle {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 60px;
        }
        .terms{
            padding-bottom: 120px!important;
        }
       
    }
}

</style>