<template>
  <div class="baner">
      <div class="holdContent">
        <img 
        :src="
          production ? 
          path_prefix.slice(0, -1).concat(require('../../assets/images/baner.svg')):
          require('../../assets/images/baner.svg')" >
      </div>
  </div>
</template>

<script>
export default {
    name: "widgetBlack",
    data () {
        return {
            production: false,
            path_prefix: null,
        }
    },
    mounted() {
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix
    }
} 
</script>

<style lang="scss" scoped>
.baner {
    // width: 300px;
    // height: 600px;

    //background: #050807;
    width: 230px;
    height: 345px;
    margin-top: 25px;
    // position: sticky;
    // top: 25px;

    .holdContent {
        // padding:25px;
         background: #050807;
         border-radius: 5px;
         margin:0 auto;
         width:100%;
         box-sizing: border-box;
         height: 100%;
         position:relative;
         overflow: hidden;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
        }

    }
}
@media only screen and (max-width: 1080px){// 1280px
    .baner{
        width: initial;
        height: 385px ;
        padding-bottom: 40px;
        margin-left: 0px;
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            //width:1278px;
            width: calc(100% - 58px );
            margin: 0 29px;
            height: 100%;
            background-size: cover;
            img{
                position: initial !important;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}
@media only screen and (max-width: 368px){ //375
    .baner{
        height: initial;
        min-height: 300px;
        //padding-bottom: 0px;
    }
}

</style>
