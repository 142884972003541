<template>
    <div class="login">
       <loginComponent 
            @login="loginuser"
            @clearmsgerr="msg=''"
            :msgerr="msg" 
        />
    </div>
</template>

<script>
import { api } from '@/api'
import loginComponent from '@/components/loginComponents/loginComponent'
export default {
    name: 'login',
    components: {
        loginComponent,
    },
    data () {
        return {
            msg:'',

        }

    },
    mounted(){
    },
    methods: {
        loginuser(e) {
            api.logInUser(e).then(res => {
                // this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.data.sid)
                localStorage.setItem('usr_id', res.data.data.usr_id)
                // localStorage.setItem('userName', res.data.name)
               this.$store.commit('changeLoged', true)
                // this.$store.commit('setUserName', res.data.name)
                // this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid, res.data.data.usr_id) 
                // localStorage.setItem('sid', res.data.data)
                this.$router.go(-1)
                //  localStorage.setItem('sid', res.data.data)
                 this.$store.commit('setUserPrivilege', res.data.data.usr_privilege)
            }).catch(e => {
             this.msg='msg.userOrPass'
             console.log(e.message);
            })

        },
        getUserData(sid, id) {
            let params = {
                sid : sid,
                id : id
            }
            api.getUserData(params).then(res => {
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        }
    },
    watch:{
        // 'step.successShow':{
        //     handler(newVal){
        //         if(newVal){
        //             setTimeout(() => {
        //                 this.step.successShow = false
        //                 this.step.enterPassShow= true
        //             }, 10000)
        //         }

        //     }
        // }
    }

}
</script>

<style lang="scss" scoped>
.login{
    background-color: var(--main-background-color);
    display: flex;
    // width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    align-items: center;
    // padding-top: 87px;
    
}
@media only screen and (max-width: 500px){
    .login{
        height: initial;
    }
}
</style>
