<template lang="html">
  <div class="aspect-wrapper" >
    <router-link  :to="{ name: 'singleBlog', params: { lang: this.$i18n.locale, id: blogData.link.slice(6) }}">
      <div class="aspWrap">
      <div class="aspect">
        <div class="blog-ad-wrapper">
          <img v-if="blogData.image" 
                :src="setLink(blogData.image)" :alt="blogData.img_alt ? blogData.img_alt : 'main-blog-image'">
          <img  v-else-if="default_thumb" 
                :src="setLink(default_thumb)" />
          <img  v-else 
                :src="
                  production ? 
                  path_prefix.slice(0, -1).concat(require('../../assets/images/defaultThumb.png')):
                  require('../../assets/images/defaultThumb.png')
                "
        />
          </div>
      </div>
      </div><!-- aspWrapp -->
      
      <span class="date">{{setDate}}</span>
      <div class="title">
        {{blogData.title}}
      </div>
    </router-link>
  </div>
</template>

<script>
import { baseURL, baseURLimage } from '@/api'
import helperDate from '@/js/helperDate.js'
export default {
  // section Related content
  name: 'blogAd',
  props: {
    blogData: {
      required: true,
      type: Object
    }
  },
  beforeMount(){
    // this.link = this.blogData.link.substring(String('/blog/').length)
    // console.log(this.link)
  },
  mounted(){
    this.setDate = helperDate.formatDate(this.blogData.date)
    
    this.production = process.env.NODE_ENV === 'production'
    this.path_prefix = window.__blog_path_prefix

    let config = JSON.parse( localStorage.getItem('config'))
    this.default_thumb = Object.hasOwnProperty.call(config,'default_thumb') ? config.default_thumb : null
 
  },
  data(){
    return{
      setDate: null,
      locale: null,
      link: null,
      production: false,
      path_prefix: null,
      default_thumb: null

    }
  },
  computed: {
    baseURL () {
      return baseURL
    }
  },
  methods: {
    openBlog () {
      // this.$router.push(this.blogData.link)
      this.$router.push({ path:this.$i18n.locale+'/'+this.blogData.link, params: { lang: this.$i18n.locale } })
    },
    setLink(link){
      // return baseURL.replace('/blog-template/api/', link)
      // return baseURLimage + link
      if(this.production){
          if(link.startsWith('/')){
            return link
          } else {
            return '/'+link
          }
        }
     return baseURLimage + link
    },
  }
}
</script>

<style lang="scss" scoped>
.aspect-wrapper {
  width: calc(33% - 15px);
  margin-bottom: 52px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  .aspWrap{
    overflow: hidden;
    border-radius: 5px;
    // background: #DEE0E5;
  }
  .aspect {
    padding-top: 75%;
    position: relative;
    margin-bottom: 10px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
     // -webkit-mask-image: -webkit-radial-gradient(white, black);
    will-change: transform;
    position: relative;
    z-index: 100;
  }
  span {
    // margin-bottom: 5px;
    // font-size: 13px;
    // line-height: 24px;
    // letter-spacing: 0.05em;
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 3px;
  }
  .title {
    // font-size: 20px;
    // line-height: 26px;
    // color: var(--green-darker);
    // margin-bottom: 3px;
    // transition-duration: .3s;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;

    color: var(--title-color);
  }
  // .title:hover {
  //   color: #000000;
  // }
}
.blog-ad-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  will-change: transform;

  img {
    z-index: 5;
    height: 100%;
    object-fit: cover;
    width: auto;
    border-radius: 5px;
    transition: 1s ease-in-out;
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
    -webkit-font-smoothing: antialised;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    filter: blur(0);
  }
  // img:hover {
  //   transform: scale(1.1);
  // }
}
//// hover
.aspect-wrapper:hover {
  cursor: pointer;
  .aspect {
           -webkit-border-radius: 5px !important;
            -khtml-border-radius: 5px !important;
            -moz-border-radius: 5px !important;
            border-radius: 5px !important;
            -webkit-border-top-left-radius: 5px !important;
            -webkit-border-top-right-radius: 5px !important;
            -webkit-border-bottom-left-radius: 5px !important;
            -webkit-border-bottom-right-radius: 5px !important;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            will-change: transform;
            -webkit-transform: translateZ(0);
            overflow: hidden;

    .blog-ad-wrapper {
       border-radius: 5px !important;
       overflow: hidden;
      img {
        transform: scale(1.1);
      }
    }
  }
  .title {
    color: var(--title-color);
  }
}
@media only screen and (max-width: 768px){
  .aspect-wrapper {
    width: calc(50% - 15px);
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 450px){
  .aspect-wrapper {
    width: 100%;
  }
}
</style>
