import axiosBase from 'axios';

let baseURLimage = process.env.VUE_APP_API_URL_IMG
// let baseURL = process.env.VUE_APP_API_URL
let baseURL = window.__blog_path_prefix + 'api/'
if (process.env.NODE_ENV !== 'production') {
  baseURL = process.env.VUE_APP_API_URL_DEV
} else {
  baseURLimage = ''
}
const axios = axiosBase.create({
  baseURL
})

const api = {
  getStats: params => axios({
    url: 'users',
    method: 'GET',
    params: params
  }),
  getReport: params => axios({
    url: `report/${params.hash}/${encodeURIComponent(params.key)}/${params.odometer}`,
    method: 'GET',
    params: params
  }),
  sendFeedback: params => axios({
    url: 'feedback',
    method: 'POST',
    params: params
  }),
  login: params => axios({
    url: 'users/login',
    method: 'POST',
    params: params
  }),
  logOut: params => axios({
    url: 'logout',
    method: 'GET',
    params
  }),
  checkSession: params => axios({
    url: '/auth/check-session',// 'users/checkSession',//api/users/checkSession
    method: 'POST', //'GET',
    params
  }),
  getBlogData: params => axios({
    // url: `blog/${params.id}`,
    url: `${params.id}`,
    method: 'GET',
    params: params
  }),
  getBlogs: params => axios({
    url: `blog`,
    method: 'GET',
    params: params
  }),
  // return all widouth locale
  getAdminBlogs: params => axios({
    url: `admin/blog`,
    method: 'GET',
    params: params
  }),
  getAdminBlog: params => axios({
    url: `admin/blog/${params.id}`,
    method: 'GET',
    params: params
  }),
  createBlog: params => axios({
    url: 'admin/blog',
    method: 'POST',
    data: params
  }),
  editBlog: params => axios({
    url: 'admin/blog',
    method: 'PUT',
    data: params
  }),
  deleteBlog: params => axios({
    url: `admin/blog/${params.id}`,
    method: 'DELETE',
    // data: params
  }),
  addLikes: params => axios({
    url: `/blog/${params.link}/like`,
    method: 'POST',
    data: params
  }),
  deleteLikes: link => axios({
    url: `/blog/${link}/like`,
    method: `DELETE`,
  }),
  addMedia: params => axios({
    url: 'admin/images',
    method: 'POST',
    data: params
  }),
  getImages: params => axios({
    url: 'admin/images',
    method: 'GET',
    params: params
  }),
  deleteImage: id => axios({
    url: `admin/images/${id}`,
    method: 'DELETE'
  }),
  duplicateBlog: (params, id) => axios({
    url: `admin/blog/${id}/translate`,
    method: 'POST',
    data: params
  }),
  signUpNewsltter: params => axios({
    url: 'newsletter', // newsletter
    method: 'POST',
    data: params
  }),
  //user
  signUpUser: params => axios({
    url: 'user/register',
    method: 'POST',
    data: params
  }),
  logInUser: params => axios({
    url: 'auth/login',
    method: 'POST',
    data: params
  }),
  logOutUser: params => axios({
    url: `user/logout`,
    method: 'GET',
    params: params
  }),
  getUserData: params => axios({
    // url: `user/profile/${params}`,
    // url: `user/profile`,
    url: `users/${params.id}`,
    method: 'GET',
    // params: params
  }),
  editUserData: (params, id) => axios({
    url: `users/${id}`,
    method: 'PUT',
    data: params
  }),
  loginGoogle: params => axios({
    url: 'user/google',
    method: 'POST',
    data: params
  }),
  loginFacebook: params => axios({
    url: 'user/facebook',
    method: 'POST',
    data: params
  }),
  forgot: params => axios({
    url: 'user/forgot-password',
    method: 'GET',
    params: params
  }),
  recover: params => axios({
    url: 'user/forgot-password',
    method: 'POST',
    data: params
  }),
  // configuration

  getSiteConfigurations: () => axios({
    url: `config/objects`,
    method: 'GET'
  }),
  getConfigurations: () => axios({
    url: `/config`,
    method: 'GET'
  }),
  editConfigurations: params => axios({
    url: `/config`,
    method: 'PUT',
    data: params
  }),
  //categorise
  getCategories: () => axios({
    url: `/blog/categories`,
    method: 'GET',
  }),
  deleteCategories: params => axios({
    url: `/blog/categories`,
    method: 'DELETE',
    data: params
  }),
  editCategory: params => axios({
    url: `/blog/categories`,
    method: 'PUT',
    data: params
  }),
  addCategory: params => axios({
    url: `/blog/categories`,
    method: 'POST',
    data: params
  }),

  // comments
  getBlogComent: params => axios({
    url: `/blog/comment`,
    method: 'GET',
    params: params
  }),
  postComent: params => axios({
    url: `/blog/comment`,
    method: 'POST',
    data: params
  }),
  getBlgAll: params => axios({
    url: `/admin/blog/all`,
    method: 'GET',
    params: params
  }),
  getAdminComments: params => axios({
    url: `/admin/comments2`,
    method: 'GET',
    params: params

  }),
  deleteComments: params => axios({
    url: `/admin/comments`,
    method: 'DELETE',
    data: params
  }),
  approveComment: params => axios({
    url: `admin/comments`,
    method: 'PATCH',
    data: params
  })


}

axios.interceptors.request.use(config => {
  handleRequestInterceptor(config)
  // console.log(config);
  return config
})

function handleRequestInterceptor(config) {
  try {
    const sid = localStorage.getItem('sid')
    if (config.params && sid) {
      config.params.sid = sid
    } else if (sid) {
      config.params = {
        sid
      }
    }
  } catch (err) {
    // console.log('err', err)
  }
}

export {
  baseURL,
  baseURLimage,
  api
}
