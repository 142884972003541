<template>
    <div v-if="crumpList" class="wrappCrump">    
        <div v-for="(crump, index) in crumpList" 
            :key="index"
            @click="selectCrump(crump)"
            :class="{
                active: selected.id === crump.id, 
                crump: true
            }"
        >
            {{ crump.label }}
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'crump',
    props: {
      crumpList:{
        default: []
      }
    },
    data(){
      return{
        selected:{}
        
      }
    },
    mounted() {
        this.selected = this.crumpList[0]
        // this.selectCrump(this.crumpList[0])
    },
    methods:{
        selectCrump(e){
            this.selected = e
            this.$emit('select', e)

        }
  
    },
    computed: {
      
    },
    watch:{
    //   msg: {
    //     handler (newM) {
    //       if(newM === true) {
    //         setTimeout(() => {
    //           this.msg = false
    //         }, 5000);
    //       }
    //     }
    //   }
    }
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .wrappCrump{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--comments-background-color);
    color: var(--text-color);
    border-radius: 89px;
    width: fit-content;
    max-width: 100%;

    .crump{
        height: 50px;
        padding: 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        // border-radius: 20px;
        border-radius: 89px;
        cursor: pointer;
        text-transform: capitalize !important;

    }
    .active{
         background-color: var(--btn-color);
         color: var(--main-background-color);
    }
  }
  
  </style>
 