import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocialSharing from 'vue-social-sharing'
import vClickOutside from 'v-click-outside'
import i18n from './i18n'
import vueClapButton from 'vue-clap-button'
import Clipboard from 'v-clipboard'
import "@/assets/css/vars.css";
import VueMeta from 'vue-meta'

import { VueReCaptcha } from 'vue-recaptcha-v3'


let token = process.env.VUE_APP_CAPTCHA_TOKEN
if (window.__recaptcha_key_client)
  token = window.__recaptcha_key_client
if (token) {

  Vue.use(VueReCaptcha, { siteKey: token })
}




Vue.config.productionTip = false

Vue.use(VueSocialSharing, {
  networks: {
    // fakeblock: 'https://fakeblock.com/share?url=@url&title=@title',
    messenger: 'fb-messenger://share/?link=@u'
  }
})
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
Vue.use(vClickOutside)
Vue.use(vueClapButton)
Vue.use(Clipboard)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
