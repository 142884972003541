<template>
    <div class="wrappAdminComments">
        comments
        <div class="wrappD">
            <drop 
            :defaultText="$t('comment.select')" 
            :selected="blog" 
            :list="blogs"
            :removable="true"
            @remove="removeBlg"
            @changeOption="setBlg"
             />
            <drop :defaultText="$t('comment.select')" :selected="viewCom" :list="getCommentsView" @changeOption="seeBy" />
            <search @search="search" />

        </div>
        <div class="wrappStatuses">
            <crump :crumpList="getCommentsStatuses" @select="selectStatus" />
        </div>

        <adminComponents v-for="(com, index) in comments" :key="index" :comment="com" @refresh="refresh" />
        <div v-if="more" @click="getComments()" class="show-more">
            {{ $t('main.showMore') }}
        </div>
    </div>
</template>

<script>
import drop from '../inputcomponents/drop.vue';
import search from '../inputcomponents/search.vue'
import crump from '../inputcomponents/cramp.vue'
import adminComponents from '../comments/adminComments.vue'
import { api } from '@/api'
import { mapGetters } from 'vuex';
export default {
    name: "adminComment",
    components: {
        drop,
        crump,
        search,
        adminComponents
    },
    activated() {
        // set initial
        this.viewCom = this.getCommentsView[0]
        // this.send.comStatus = 1
        this.getBlgAll()
        this.refresh()
    },
    deactivated() {
        this.comments = []
    },
    data() {
        return {
            blogs: null,
            blog: null,
            viewCom: null,

            comments: [],
            more: false,

            send: {
                blg_id: null,
                comStatus: 1,
                search: null,
                showType: 1,
                count: 10,
                offset: 0
            }
        }
    },
    methods: {
        getBlgAll() {
            api.getBlgAll().then(res => {
                 // console.log(res.data)
                this.blogs = res.data.data
            }).catch(() => {
                // console.log(e)
            })
        },
        getComments() {
            let params = {}
            for (var variable in this.send) {
                if (this.send[variable] !== null)
                    params[variable] = this.send[variable]
            }
            api.getAdminComments(params).then(res => {
                this.comments.push(...res.data.data)
                this.send.offset = this.comments.length
                if (this.send.offset >= res.data.count) {
                    this.more = false
                } else {
                    this.more = true
                }
            }).catch(() => {
            })
        },
        refresh() {
            this.send.offset = 0
            this.comments = []
            this.getComments()
        },
        search(e) {
            this.send.search = e.trim() === '' ? null : e
            this.send.offset = 0
            this.comments = []
            this.getComments()

        },
        setBlg(e) {
            this.blog = e
            this.send.blg_id = e.blg_id
            this.send.offset = 0
            this.comments = []
            this.getComments()
        },
        removeBlg() {
            this.blog = null
            this.send.blg_id = null
            this.send.offset = 0
            this.comments = []
            this.getComments()

        },
        seeBy(e) {
            this.viewCom = e
            this.send.showType = e.id
            this.send.offset = 0
            this.comments = []
            this.getComments()
        },
        selectStatus(e) {
            this.send.comStatus = e.id
            this.send.offset = 0
            this.comments = []
            this.getComments()
        }
    },
    computed: {
        ...mapGetters(['getCommentsView', 'getCommentsStatuses']),
    }
}
</script>

<style lang="scss" scoped>
.wrappAdminComments {
    padding-bottom: 20px;
}

.wrappD {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 35px;

}

.wrappStatuses {
    width: 100%;
    margin: 35px 0;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.show-more {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
    cursor: pointer;
    // text-align: center;
    color: var(--text-soft-color);
}
</style>
<style lang="scss">
.wrappD {
    .dropdown-wrapper {
        width: 25%;
        min-width: 308px;
        margin: 0;
        margin: 8px 0;

        .header-wrapper {
            border-radius: 25px;
        }

    }

    .wrappSearch {
        width: 25%;
        margin: 8px 0;
        height: 39px;
    }
}

@media only screen and(max: 870px) {
    .wrappD {
        .dropdown-wrapper {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin: 0;
            margin: 8px 0;
            box-sizing: border-box;

            .header-wrapper {
                border-radius: 25px;
            }

        }

        .wrappSearch {
            margin: 8px 0;
            height: 39px;
        }
    }

}
</style>