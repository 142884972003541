<template lang="html">
  <div class="edit-blogs-wrapper create-blog-wrapper">
    <div v-if="!blog" class="no-selection">
      <search @search="search" :reset="resetSearch"/>

      <blogItem :class="{ last : index === blogs.length - 1 }" @blogClick="selectBlog(blog)" v-for="(blog, index) in blogs" :key="index" :deleteOnClick="true" :blogData="blog" />
      <div class="show-more-wrapper">
        <div v-if="more" @click="showMore()" class="show-more">
          Show more
        </div>
      </div>
    </div>
    <div v-else class="selected">
      <div @click="blog = null" class="return">
        <div class="circle">
          <img 
          :src="
          production ?
          path_prefix.slice(0,-1).concat(require('../../assets/images/icons/right_white_arr.svg')):
          require('../../assets/images/icons/right_white_arr.svg')" alt="">
        </div>
        
        return
      </div>

      <div class="input-wrapper create" v-if="$i18n.availableLocales.length > remLoc.length">
        <span class="title" @click="createDuplicate = !createDuplicate">If you want to create a duplicate blog in another language,<span class="colorBTN"> click here</span>.</span>
        <div v-if="createDuplicate" class="flex duplicate">
          <LocalePicker @setlocal="setLocal" :removeString="remLoc"/>
          <div @click="createNew" class="buttonSend" style="width:fit-content" >
            Create a duplicate
          </div>
          
          
        </div>
      </div>

      <div class="input-wrapper">
        <h1>{{ locationChanges }}</h1>
      </div>

      <div class="input-wrapper">
        <span class="title">Title: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.title">
      </div>
      <div class="input-wrapper">
        <span class="title">Description: (1 - 500 characters)</span>
        <input type="text" maxlength="500" v-model="blog.description">
      </div>
      <div class="input-wrapper">
        <span class="title">Date: </span>
        <input type="date" v-model="blog.date">
      </div>
      <div class="input-wrapper">
        <span class="title">Minutes to read:</span>
        <input type="number" max="255" v-model="blog.readTime">
      </div>
      <div class="input-wrapper">
        <span class="title">Author: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.author">
      </div>
      <div class="input-wrapper">
        <span class="title">Link: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.link">
      </div>

      <div v-if="blog.thumb === oldBlog.thumb && oldBlog.thumb != null" class="input-wrapper">
        <span class="title">Current thumb image:</span>
        <div class="current-image-wrapper">
          <img :src="showOldImage(blog.thumb)" alt="current-image" class="current-image">
          <div @click="removeImageThumb()" class="remove">
            remove
          </div>
        </div>
      </div>
      <div v-if="show_thumb" class="input-wrapper">
        <span class="title">Thumb image:</span>
        <div class="current-image-wrapper">
          <img :src="show_thumb" alt="current-image" class="current-image">
          <div @click="removeImageThumb()" class="remove">
            remove
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <span class="title">Image small (THUMB for sharing - 1.91:1 aspect ratio):</span>
        <input ref="thumb" type="file" accept=".png, .jpg, .jpeg" @change="setImageThumb($event)">
      </div>
      <div class="input-wrapper">
        <span class="title">Thumb alt: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.thumb_alt">
      </div>
      <div class="input-wrapper">
        <span class="title">Thumb name: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.thumb_name">
      </div>

      <div v-if="blog.image === oldBlog.image && oldBlog.image !=null" class="input-wrapper">
        <span class="title">Current main image:</span>
        <div class="current-image-wrapper">
          <img :src="showOldImage(blog.image)" alt="current-image" class="current-image">
          <div @click="removeImage()" class="remove">
            remove
          </div>
        </div>
      </div>
      <div v-if="show_image" class="input-wrapper">
        <span class="title">Main image:</span>
        <div class="current-image-wrapper">
          <img :src="show_image" alt="current-image" class="current-image">
          <div @click="removeImage()" class="remove">
            remove
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <span class="title">New main image: (0 - 1.5mb)</span>
        <input id="image" type="file" accept=".png, .jpg, .jpeg" @change="setImage($event)">
      </div>
      <div class="input-wrapper">
        <span class="title">Image alt: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.img_alt">
      </div>
      <div class="input-wrapper">
        <span class="title">Image name: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.img_name">
      </div>
      <dropdown 
        :defaultText="$t('main.status')"
        :selected="blog.sta_name"
        :list="getBlogStatuses"
        @changeOption="setStatus"
       />


    <div class="input-wrapper">
          <span class="title">Categories: </span>
          <div class="setCheck">
            <div class="itemCheck" v-for="(caat, index) in categoriseApi" :key="index">
              <input v-if="caat.cat_deleted !=1" type="checkbox"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="checkedList" >
              <label v-if="caat.cat_deleted !=1" class='caat' :for="caat.cat_id">{{caat.cat_name}}</label>
            </div>
          </div>
        </div>
      <div class="input-wrapper">
        <span class="title">Content: (1 - 60 000 characters)</span>
        <vue-editor v-model="blog.content"></vue-editor>
        <div class="preview">
          <div class="title">Preview:</div>
          <div v-html="getPreview" class="content" id="previewId">
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div @click="resetPage" class="buttonCancel">
          Reset
        </div>
        <div @click="edit" class="buttonSend">
          Edit
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL, api, baseURLimage} from '@/api'
import blogItem from '@/components/blog/blogItem.vue'
import { VueEditor } from "vue2-editor";
import dropdown from "../inputcomponents/drop.vue"
import search from "../inputcomponents/search.vue"
import { mapGetters } from 'vuex';
import LocalePicker from '../LocalePickerForm.vue'

export default {
  name: 'editBlog',
  components: {
    blogItem,
    VueEditor,
    dropdown,
    LocalePicker,
    search

  },
  data () {
    return {
      blogs: [],
      offset: 0,
      count: 6,
      more: true,
      blog: null,
      oldBlog: null,
      required: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: null,
        link: null,
      },
      createDuplicate: false,
      new_locale: null,
      resetSearch: false,
      remLoc: null,
      locationChanges: null,
      show_thumb: null,
      show_image: null,

      categoriseApi: null,
       checkedList:[],

       //
       production: false,
       path_prefix: null


    }
  },
  activated () {
    this.getBlogs()
    this.resetSearch = !this.resetSearch
    this.production = process.env.NODE_ENV === 'production'
    this.path_prefix = window.__blog_path_prefix // ovo zavisi kako ce bek da 
  },
  methods: {
    async setImage (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('image too big')
        document.querySelector('#image').value = null
        return
      }
      this.blog.image = e.target.files[0]
      this.show_image = await this.showImage(e.target.files[0])
    },
    async setImageThumb (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('thumb is too big')
        this.$refs.thumb.value = null
        return
      }
      this.blog.thumb = e.target.files[0]
      this.show_thumb = await this.showImage(e.target.files[0])

    },
    validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === 'paste') {
          key = event.clipboardData.getData('text/plain');
      } else {
      // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
      var regex = /[0-9-]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    getBlogs (search) {

      // let params = {
      //   count: this.count,
      //   offset: this.offset,
      //   // locale: this.$i18n.locale
      // }
      let params = {}

      if (search) {
        if(Object.hasOwnProperty.call(search, 'title')) {
          params.search = search.title
          this.offset = 0
          this.blogs = []
        }
        if(Object.hasOwnProperty.call(search, 'locale')) {
          params.locale= search.locale
          this.offset = 0
          this.blogs = []
        }
      }
      params.count= this.count
      params.offset= this.offset

      api.getAdminBlogs(params).then(res => {
        this.blogs = res.data.data
        if (this.blogs.length === res.data.count) {
          this.more = false
        }
      }).catch(e => {
        console.log(e);
      })
    },
    resetPage () {
      this.oldBlog.link = '/blog/' + this.oldBlog.link
      this.selectBlog(this.oldBlog)
    },
    selectBlog (blog) {
      this.setCategory()
      this.locationChanges = null
      let params = {
        slug: blog.link.substring(6, blog.link.length),
        locale: blog.locale,
        id: blog.id
      }
      api.getAdminBlog(params).then(res => {
        this.blog = JSON.parse(JSON.stringify(res.data.data))
        this.blog.status =  res.data.data.sta_id
        this.blog.delete_image = false
        this.blog.date = this.blog.date.substring(0, 10)
        this.oldBlog = JSON.parse(JSON.stringify(res.data.data))
        this.oldBlog.delete_image = false
        this.oldBlog.date = this.oldBlog.date.substring(0, 10)
        this.oldBlog.status =  res.data.data.sta_id
        this.locationChanges = this.getNameOfLang[this.blog.locale]
        this.createDuplicate = false

/*
        this.remLoc = [this.blog.locale]
        if(this.blog.all_locales && this.blog.all_locales.length > 0) {
          this.remLoc = this.remLoc.concat(this.blog.all_locales)
        } 
    */      // console.log(this.remLoc)
        this.remLoc = this.blog.all_locales
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.clickEl()

      }).catch(e => {
        console.log(e);
      })
    },
    edit () {
      if (!this.checkAllParams()) {
        return
      }
      let changes = new FormData()
      for (var variable in this.blog) {
        if (Object.prototype.hasOwnProperty.call(this.blog, variable)) {
           if((variable === 'thumb' && this.blog[variable] === null) 
           || (variable === 'image' && this.blog[variable] === null)
           || variable === 'categories' 
           || variable === 'available_locales'
           || variable === 'all_locales') {
                  console.log('')
           } else if (this.blog[variable] !== this.oldBlog[variable]) {
            // changes[variable] = this.blog[variable]
            changes.append(variable, this.blog[variable])
          }
        }
      }

      if(this.blog.categories === null && this.checkedList.length > 0){
        changes.append('categories', JSON.stringify(this.checkedList))
      }else if(this.blog.categories === null && this.checkedList.length === 0) {
        console.log('')
      }else{
        // for(let i = 0; this.blog.categories.length;i++){
        //   console.log('h')
        // }
        changes.append('categories', JSON.stringify(this.checkedList))
      }
      

      // changes.id = this.blog.id
      if (changes.entries().next().done) {
        return
      }
      changes.append('id', this.blog.id)
      // let params = new FormData()
      // for (var variablee in this.changes) {
      //   params.append(variablee, this.changes[variablee])
      //   console.log(params[variablee]);
      // }
      api.editBlog(changes).then(() => {
        
        alert('Blog edited successfuly')
        this.removeImage()
        this.removeImageThumb()
        this.blog = null
        this.oldBlog = null
        this.getBlogs()
      }).catch(e => {
        alert('Error editing blog ' + e.response.data.message)
      })
    },
    checkAllParams () {
      for (var variable in this.required) {
        if (this.blog[variable] === null || this.blog[variable] === '') {
          alert(this.getName(variable) + ' is missing!')
          return false
        }
      }
      return true
    },
    getName (variable) {
      if (variable === 'img_alt') {
        return 'image alt'
      }
      if (variable === 'img_name') {
        return 'image name'
      }
      if (variable === 'readTime') {
        return 'minutes to read'
      }
       if (variable === 'thumb') {
        return 'thumb image'
      }
      if (variable === 'thumb_name') {
        return 'thumb name'
      }
      if (variable === 'thumb_alt') {
        return 'thumb alt'
      }
      return variable
    },
    showImage (input_file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort()
          reject(new DOMException("Problem parsing input file."))
        };

        reader.onload = () => {
          resolve(reader.result)
        }

        reader.readAsDataURL(input_file)
      })
    },
    removeImage () {
      this.blog.delete_image = true
      this.blog.image = null
      this.show_image = null
      document.querySelector('#image').value = null
    },
    removeImageThumb () {
      this.blog.delete_thumb = true
      this.blog.thumb = null
      this.show_thumb = null
      this.$refs.thumb.value = null

    },
    showMore () {
      let currOffset = this.blogs.length
      let params = {
        count: this.count,
        offset: currOffset,
        locale: this.$i18n.locale
      }
      api.getAdminBlogs(params).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.blogs.push(res.data.data[i])
        }
        if (this.blogs.length >= res.data.count) {
          this.more = false
        }else {
          this.more = true
        }
      }).catch(e => {
        console.log(e);
      })
    },
    setStatus(e) {
      // console.log(e)
      this.blog.status = e.id
      this.blog.sta_id = e.id
      this.blog.sta_name = e.label
    },
    setLocal(e){
      // console.log(e)
      this.new_locale = e

    },
    createNew () {
      // console.log('create new locale')
      if(!this.new_locale) {
        alert('you have to SELECT LOCALE')
        return
      }
      let params = {
        slug: this.blog.link +'_'+this.new_locale,
        new_locale: this.new_locale,
        id:this.blog.id
      }
   
      api.duplicateBlog(params,params.id).then(res => {
   
        // this.selectBlog(res.data.data)
        this.selectBlog ({
          link:'/blog/'+res.data.data.link, 
          locale: res.data.data.locale, 
          id:res.data.data.id 
        })
      }).catch(e => {
        console.log(e);
      })


    },
    search(e) {
      // console.log(e)
      let params = {
        title: e
      }
      this.getBlogs(params)

    },
    async setCategory(){
      //  await axios.get("http://028x122.mars-t.mars-hosting.com/api/news-categories"
      //     ).then((res)=>{
      //        console.log(res.data.data)
      //        this.categoriseApi = res.data.data   
      //     }).catch((e)=>{
      //      console.log(e)
      //     })

     await api.getCategories().then(res => {
        // console.log(res.data.data)
        this.categoriseApi = res.data.data
      }).catch(e => {
        console.log(e)
      })
    },
    colorSpan(){
      var getEl = document
        .getElementById("previewId")
        .getElementsByTagName("img"); // document.getElementsByTagName("img");
      for (let i = 0; i < getEl.length; i++) {
        getEl[i].style.maxWidth = 100+"%";
      }
    },
    clickEl(){
      for (let i = 0; i < this.blog.categories.length; i++) {
            setTimeout(() => {           
                document.getElementById(this.blog.categories[i].cat_id).click();    
            }, 10);
          }
        },
    showOldImage(link) {
      // return baseURLimage + link
      if(this.production){
          if(link.startsWith('/')){
            return link
          } else {
            return '/'+link
          }
        }
     return baseURLimage + link

    }
    
  },
  watch: {
    'blog.content': {
      handler (){
        let date =  Date.now();
        setTimeout(() => {
          if (date + 100 <= Date.now()) {
            // console.log("da");
            this.colorSpan();
          } else {
            // console.log("ne");
          }
        }, 100);

      }
    }

  },
  computed: {
    ...mapGetters(['getBlogStatuses', 'getNameOfLang']),
    getPreview () {
      return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    },
    baseURL () {
      return baseURL.replace('/blog-template/api/', '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/adminBlogForm';
.colorBTN{
  color: var(--btn-color);
}

</style>
