<template >
    <div class="image" v-if="con">
        <div class="title">{{ con.id }}</div>
        <div class="wrapIn">
            <img v-if="main_logo" :src="main_logo" alt="">
        <img v-else 
        :src="returnUrl(con.value)"
         alt="">
        <input ref="main_logo" type="file" accept=".png" @change="set_main_logo($event)">
        </div>
        
    </div>
</template>
  
<script>
import { baseURLimage } from '@/api'
export default {
    name: 'addImg',
    props: {
        con: {
            type: Object,
            default: null
        },
        maxSize: {
            type: Number,
            default: 1500000
        }
    },
    data() {
        return {
            main_logo: null,
            production: false,
        }
    },
    mounted() {
        this.production = process.env.NODE_ENV === 'production'
    },
    methods: {
        returnUrl(url) {
            if(this.production){
                if(url.startsWith('/')){
                    return url
                } else {
                    return '/'+url
                }
            }
            return baseURLimage + url
    
        },
        showImage(input_file) {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onerror = () => {
                    reader.abort()
                    reject(new DOMException("Problem parsing input file."))
                };

                reader.onload = () => {
                    resolve(reader.result)
                }

                reader.readAsDataURL(input_file)
            })
        },
        async set_main_logo(e) {
            if (e.target.files && e.target.files[0].size >= this.maxSize) {
                alert('image is too big')
                this.$refs.main_logo.value = null
                return
            }
            this.send_main_logo = e.target.files[0]
            this.main_logo = await this.showImage(e.target.files[0])
            this.$emit('setimg', e.target.files[0])

        },

    }
}
</script>
  
<style lang="scss" scoped>
.image {
    margin-right: auto;
    width: 831px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .title {
        width: 150px;

    }
    .wrapIn{
        width: 551px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img {
        height: 70px;
        width: auto;
        max-width: 70%;
        overflow: hidden;
        margin: 10px 0;
        margin-right: 50px;

    }
    input {
        width: 185px;
        text-align: right;
    }


}
@media only screen and (max-width:1100px){
    .image{
        flex-wrap: wrap;
        height: fit-content;

    }
}

</style>