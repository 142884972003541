<template lang="html">
  <div class="">
    <div class="blog-list-wrapper">
      <div class="wrappTitle">
        <h1>{{$t('main.maintitle')}}</h1>
        <div class="line"></div>
      </div>
      <div class="content">
        <div v-if="blogs.length > 0" class="leftSide">
          <blogItem :class="{ last : index === blogs.length - 1 }" v-for="(blog, index) in blogs" :key="index" :blogData="blog" />
          <div class="show-more-wrapper">
              <spiner v-if="!more && showSpiner" />
              <div v-if="more" @click="getBlogsN()" class="show-more">
              {{$t('main.showMore')}}
            </div>
          </div>
        </div>
      <!--end v-if leftSide-->
        <div v-else class="leftSide">
          <spiner v-if="showSpiner" />
            <div v-else class="not_found_blog">
              <div class="wrapp">
                <img v-if="blog_not_found" :src="setUrl(blog_not_found)" alt="not found"/>
                <img v-else 
                :src="
                  production ?
                  path_prefix.slice(0, -1).concat(require('../assets/images/not_found_blog.svg')) : 
                  require('../assets/images/not_found_blog.svg')
                  "
                alt="not found"
                >
                <div class="text" v-html="$t('main.notBlogFound')">
                </div>
              </div>
            </div>
        </div><!--end v-else leftSide-->
        <div class="rightSide">
          <search @search="search"/>
          <widgetBlack v-if="!showWidget"/>
        </div>
      </div><!-- end content-->
    </div>
    <widgetBlack v-if="showWidget" />
  </div>
</template>

<script>
import { api, baseURLimage } from '@/api'
import blogItem from '@/components/blog/blogItem.vue'
import widgetBlack from '../components/widgete/widgetBlack'
import search from '../components/inputcomponents/search.vue'
import spiner from '../components/spiner.vue'
export default {
  name: "blogList",
  components: {
    blogItem,
    // newsletter,
     widgetBlack,
     search,
     spiner
  },
  data () {
    return {
      blogs: [],
      offset: 0,
      count: 3,
      more: true,
      title: 'Blog',
      description: 'Blog',
      showWidget: false,
      showMobileNav:false,
      showSpiner: false,
      logo: null,
      blog_not_found: null,
      production: false,
      path_prefix: null,
      searchParam: ''

    }
  },
   metaInfo () {
        return {
        title: 'Blog',
        titleTemplate: '%s - ' + this.title ,
        meta:[
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.description
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content:  this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: 'Blog' 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                {
                    vmid: 'og:image',
                    property: 'og:image',
                    itemprop: 'image',
                    content: this.setUrl(this.logo)
                }
            ]
        }
    },
    created() {
      this.production = process.env.NODE_ENV === 'production'
      this.path_prefix = window.__blog_path_prefix

      
      window.addEventListener("resize", this.resize)
        this.resize()
      let config = JSON.parse( localStorage.getItem('config'))
        this.logo = Object.hasOwnProperty.call(config,'login_logo') ? config.login_logo : null
        this.blog_not_found = Object.hasOwnProperty.call(config,'blog_not_found') ? config.blog_not_found : null
   
    },
  mounted () {
    this.getBlogsN()
 
  },
  methods: {
    search(e) {
      this.searchParam = e
      this.offset = 0
      this.blogs = []

      this.getBlogsN()

    },
    getBlogsN () {
      this.showSpiner = true
      let params = {}

        if (this.searchParam !== '') {
            params.search = this.searchParam
            
        }
        params.count= this.count
        params.offset= this.blogs.length
        params.locale= this.$i18n.locale

      api.getBlogs(params).then(res => {
          for (var i = 0; i < res.data.data.length; i++) {
            this.blogs.push(res.data.data[i])
          }
          if (res.data.count <= this.blogs.length ) {
            this.more = false
          }else {
            this.more = true
          }
          this.showSpiner = false
      }).catch(e => {
        console.log(e);
        this.showSpiner = false
      })

    },
    resize () {
        if (window.innerWidth <= 500) {
            this.showMobileNav = true
            this.showWidget = true
        } else if (window.innerWidth <= 1080) {
            this.showMobileNav = false
            this.showWidget = true
        } else {
            this.showMobileNav = false
            this.showWidget = false
        }
      },
      setUrl(link) {
        return baseURLimage + link
    }

  },
  watch: {
    '$route': function () {
      this.offset= 0
      this.count= 3
      // this.getBlogs()
      this.searchParam = ''
      this.blogs = []
      this.getBlogsN()
    },
  },
  // created(){
      
  // },
  destroyed() {
        window.removeEventListener("resize", this.resize);
  }
}
</script>

<style lang="scss" scoped>
.blog-list-wrapper {
  min-height: calc(100vh - 250px);
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 6px;
  box-sizing: border-box;
  padding-top: 60px;
  text-align: cente;

  .content {
    display: flex;
   
  }
  .leftSide {
    position: relative;
    width:100%;

    .not_found_blog{
      // height: calc(100vh - 80px);
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapp{
        max-width: 100%;
        position: relative;
        img {
          max-width: 100%;
        }
        .text{
          font-weight: 700;
          font-size: 36px;
          text-align: center;
          color:var(--btn-color);
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

  }
  .rightSide {
    // display: flex;
    padding-top: 10px;
    margin-left: auto;
    margin-left: 40px;
    position: sticky;
    top: 25px;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    background-color: var(--main-background-color);
  }
  .wrappTitle{
    width: fit-content;
    margin: auto;
    margin-bottom: 45px;
    .line{
      height: 7px;
      background-color: var(--btn-color);
    }
  }

  h1 {
    margin-bottom: 0px;
    font-size: 40px;
    line-height: 46px;
    color: var(--btn-color);
  }
  .show-more-wrapper {
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
  }
}
@media only screen and (max-width: 1350px){
  .blog-list-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;

    h1 {
      font-size: 34px;
    }
  }
}
@media only screen and (max-width: 1080px) {
  .content {
    flex-direction: column;

  .leftSide {
    order: 2;

  }
  .rightSide {
    // top: 0px;
    position: initial;
    order: 1;
    width: 100%;
    margin-left: 0;
    z-index:100;
    }
  }
}

@media only screen and (max-width: 500px){
  .blog-list-wrapper {
    padding-top: 22px;
    .wrappTitle{
      width: fit-content;
      margin: auto;
      margin-bottom: 35px;
      .line{
        height: 3px;
        background-color: var(--btn-color);
      }
    }

    h1 {
      font-size: 30px;
      // margin-bottom: 47px;
    }
  }
}
</style>
