<template>
    <div class="not_found">
        <div class="top">
            <div class="title">{{ $t('main.notFound') }}</div>
            <router-link :to="{name: 'blog', params:{ lang: $i18n.locale } }" class="buttonSend">{{ $t('main.returnHome') }}</router-link>
        </div>
        <div class="bottom">
            <img  v-if="page_not_found" 
                :src="setLink(page_not_found)" />
            <img  v-else 
                :src="
                    production ? 
                    path_prefix.slice(0, -1).concat(require('../assets/images/not_found.svg')):
                    require('../assets/images/not_found.svg')
                    "
            />
        </div>

    </div>
</template>
<script>
import { baseURLimage } from '../api';
export default {
    data(){
        return{
            production: false,
            path_prefix: null,
            page_not_found: null
        }
    },
    mounted() {
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix

        let config = JSON.parse( localStorage.getItem('config'))
        this.page_not_found = Object.hasOwnProperty.call(config,'page_not_found') ? config.page_not_found : null
    },
    methods:{
        setLink(link){
            return baseURLimage + link
        },
    }
    
}
</script>
<style lang="scss" scoped>
@import '../assets/css/buttonStyle.scss';
.not_found {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top {
        width:fit-content;
        margin-bottom: 121px;
        .title{
            font-weight: 700;
            font-size: 26px;
            // line-height: 60px;
            color: var(--title-color);
            margin-bottom: 20px;
        }

    }
    .bottom {
        max-width: 100%;
        img{
            max-width: 100%;
        }

    }

}
</style>