<template>
    <div class="config">
        <h2>Configuration</h2>
        <!-- <div v-for="(con, index) in config" :key="index" class="wrapp" >
            <div  v-for="(value, key, ind) in con" :key="key+ind">
                <div class="wrapInput" v-if="key !== 'explanation' && key !== 'login_logo' && key !== 'main_logo' ">
                    <div class="title">{{ key }}</div>
                    <input type="text" v-model="config[index][key]">
                </div>
                <div class="info" 
                    v-else-if="key === 'explanation' 
                        && !Object.hasOwnProperty.call(con, 'main_logo')  
                        && !Object.hasOwnProperty.call(con, 'login_logo')"
                    >
                    <img src="../../assets/images/forgotpassHover.svg" alt="" @mouseover="setHover=index" @mouseleave="setHover= -1">
                    <div v-if="setHover === index" class="tooltip">{{ config[index][key]}}</div> 
                </div>
                <div class="image"
                     v-else-if=" Object.hasOwnProperty.call(con, 'main_logo')  
                     && key != 'explanation'"
                 >  <div class="title">{{ key }}</div>
                    <img v-if="main_logo" :src="main_logo" alt="">
                    <img v-else :src="returnUrl(con.main_logo)" alt="">
                    <input ref="main_logo" type="file" accept=".png, .jpg, .jpeg" @change="set_main_logo($event)" >
                </div>
                <div class="image"
                     v-else-if="Object.hasOwnProperty.call(con, 'login_logo') && key != 'explanation'"
                 >
                    <div class="title">{{ key }}</div>
                    <img v-if="login_logo" :src="login_logo" alt="">
                    <img v-else :src="returnUrl(con.login_logo)" alt="">
                    <input ref="login_logo" type="file" accept=".png, .jpg, .jpeg" @change="set_login_logo($event)">
                </div>
            </div>    
        </div> -->
        <div v-for="(con, index) in config" :key="index" class="wrapp" >
            <div class="div">
                <div class="wrapInput"
                  v-if=" con.id !== 'login_logo' 
                    && con.id !== 'main_logo'
                    && con.id !== 'site_banner' 
                    && con.id !== 'site_footer'
                    && con.id !== 'custom_js'
                    && con.id !== 'favicon'
                    && con.id !== 'blog_not_found'
                    && con.id !== 'default_thumb'
                    && con.id !== 'page_not_found'
                    && con.id.startsWith('--')
                  ">
                    <div class="title">{{ con.id }}</div>
                    <input 
                     type="text" 
                     v-model="config[index].value" 
                     readonly
                     class="inputPicker"
                     >
                    <div class="selectPicker" :style="{background: config[index].value}" >
                        <div @click="managePicker(index)" class="fill"></div>
                        <photoshopPicker 
                            v-if="showPicker === index" 
                            @ok="ok"
                            @cancel="cancelPicker(index)"
                            @input="updateValue($event, index)"
                            v-model="config[index].value"
                            class="picker"
                        />
                    </div>
                    <info :explanation="con.explanation" />
                </div>
                <div class="wrapInput"
                 v-else-if=" con.id !== 'login_logo' 
                    && con.id !== 'main_logo'
                    && con.id !== 'site_banner' 
                    && con.id !== 'site_footer'
                    && con.id !== 'custom_js'
                    && con.id !== 'favicon'
                    && con.id !== 'blog_not_found'
                    && con.id !== 'default_thumb'
                    && con.id !== 'page_not_found'
                  ">
                    <div class="title">{{ con.id }}</div>
                    <input type="text" v-model="config[index].value">
                    <info :explanation="con.explanation" />
                </div>
                <div class="wrapInput" 
                    v-else-if=" con.id === 'site_banner'
                    || con.id === 'site_footer' 
                    || con.id === 'custom_js'"
                 >
                    <div class="title">{{ con.id }}</div>
                    <textarea type="text" v-model="config[index].value"></textarea>
                    <info :explanation="con.explanation" />
                </div>
                <div class="image wrapInput" v-if="con.id === 'main_logo'">
                    <addImg 
                        :con="con" 
                        @setimg="set_main_logo"
                    />
                    <info :explanation="con.explanation" />
                </div>
                
                <div class="image wrapInput"
                     v-else-if="con.id === 'login_logo'"
                 >
                    <addImg 
                        :con="con" 
                        @setimg="set_login_logo"
                    />
                    <info :explanation="con.explanation" />
                   
                </div>
                
                <div class="image wrapInput"
                     v-else-if="con.id === 'favicon'"
                 >
                    <addImg 
                        :con="con" 
                        @setimg="set_favicon"
                    />
                    <info :explanation="con.explanation" />
                   
                </div>

                <div class="image wrapInput"
                     v-else-if="con.id === 'blog_not_found'"
                 >
                    <addImg 
                        :con="con" 
                        @setimg="set_blog_not_found"
                    />
                    <info :explanation="con.explanation" />
                   
                </div>

                <div class="image wrapInput"
                     v-else-if="con.id === 'default_thumb'"
                 >
                    <addImg 
                        :con="con" 
                        @setimg="set_default_thumb"
                    />
                    <info :explanation="con.explanation" />
                   
                </div>

                <div class="image wrapInput"
                     v-else-if="con.id === 'page_not_found'"
                 >
                    <addImg 
                        :con="con" 
                        @setimg="set_page_not_found"
                    />
                    <info :explanation="con.explanation" />
                   
                </div>

            </div>    
        </div>
        <div class="wrappButton">
            
            <div class="buttonCancel" @click="reset">reset</div>
            <div class="buttonSend" @click="send" >send</div>
        </div>
    </div>
</template>

<script>
import { api } from '@/api'
import addImg from '../addImg.vue'
import info from '../infoConfig.vue'
import { Photoshop } from 'vue-color'
export default {
    name: 'configuration',
    components: {
        addImg,
        info,
        photoshopPicker : Photoshop

    },
    data() {
        return{
            oldConfig: [],
            config: [],
            send_login_logo: null,
            send_main_logo: null,
            send_favicon: null,
            send_blog_not_found: null,
            send_default_thumb: null,
            send_page_not_found: null,
            showPicker: -1,
            temporarilyColor: null
            

        }
    },
    activated () {
        this.getConfig()
        // this.returnUrl()

    },
    // beforeUnmount () {
    //     let confirm = window.confirm(`Are you sure you want exit changes is not saved?`)
    //         if (!confirm) {
    //             this.$emit('set')
    //         } else{
    //             this.reset()
    //         }
    // },
    deactivated() {
    // called when removed from the DOM into the cache
    // and also when unmounted

    // let confirm = window.confirm(`Are you sure you want exit changes is not saved?`)
    //         if (!confirm) {
    //             this.$emit('set')
    //         } else{
    //             this.reset()
    //         }
    if(this.checkParams())alert('New values ​​are not saved. They will be reset')
    this.showPicker = -1,
    this.temporarilyColor= null

    this.reset()
    },
    methods: {
        getConfig() {
            
            api.getConfigurations().then(res => {
                // console.log(res.data.data)
                this.oldConfig= []
                this.config= []
                this.config = res.data.data

                for(let i = 0; i < this.config.length; i++ ){
                   this.oldConfig.push({...this.config[i]})
                }
            }).catch(() => {
                // console.log(e)
            })
        },
        set_login_logo (e) {
            this.send_login_logo = e 
        },
        set_main_logo (e) {
            this.send_main_logo = e
        },
        set_favicon (e) {
            this.send_favicon = e
        },
        // add new method for new config img
        set_blog_not_found (e) {
            this.send_blog_not_found = e 
        },
        set_default_thumb (e) {
            this.send_default_thumb = e
        },
        set_page_not_found (e) {
            this.send_page_not_found = e
        },
        send() {
            let params = new FormData()
            let send = false
            for(let i = 0; i < this.config.length; i++) {
                for(let j = 0; j < this.oldConfig.length; j++){
                    if( (this.config[i].id === this.oldConfig[j].id) &&
                        this.config[i]['value'] !== this.oldConfig[j]['value']) {
                       // console.log(this.config[i])
                        params.append([this.config[i].id] ,this.config[i].value)
                        send = true
                    }
                }
            }

            if(this.send_login_logo) {
                params.append('login_logo', this.send_login_logo)
                send = true
                // console.log(this.send_login_logo)
            // return
            }
          
            if(this.send_main_logo) {
                params.append('main_logo', this.send_main_logo)
                send = true
            }

            if(this.send_favicon) {
                params.append('favicon', this.send_favicon)
                send = true
            }

////////////// add new property
            if(this.send_blog_not_found) {
                params.append('blog_not_found', this.send_blog_not_found)
                send = true
            }
          
            if(this.send_default_thumb) {
                params.append('default_thumb', this.send_default_thumb)
                send = true
            }

            if(this.send_page_not_found) {
                params.append('page_not_found', this.send_page_not_found)
                send = true
            }
///////////////            
            if (send) {
                this.sendValue(params)
            } else {
                alert('Nothing has changed')
            }

        },
        sendValue(params) {
            api.editConfigurations(params).then(() =>{
                // console.log(res)
                alert('You have successfully changed configuration.')
                this.getConfig()
                this.main_logo = null
                this.send_main_logo = null

                this.send_login_logo = null
                this.login_logo = null

            }).catch(() => {
                // console.log(e)
                alert('Something went wrong, please try again.')
            })
        },
        managePicker(e) {
            // console.log(e)
            if(e === this.showPicker) {
                this.showPicker = -1
            } else {
                this.showPicker = e
                this.temporarilyColor = this.config[e].value
            }
        },
        updateValue (e , index) {
            // console.log(JSON.stringify(e))
            // console.log(index)
            this.config[index].value = e.hex
            let r = document.querySelector(':root');
            r.style.setProperty(this.config[index].id ,this.config[index].value);
            // @input="config[index].value=$event.hex"

        },
        ok() {
           // console.log('close')
            this.showPicker = -1
        },
        cancelPicker(index) {
            // console.log(index)
            this.config[index].value = this.temporarilyColor
        },
        reset() {
            this.getConfig()
            this.setVariable()

        },
        setVariable() {
            let r = document.querySelector(':root');
            api.getSiteConfigurations().then(res => {
            // console.log(res)
            this.confData= res.data.data
            localStorage.setItem('config', JSON.stringify(this.confData))
            this.footer = this.confData['site_footer']
            localStorage.setItem('bgColor',this.confData['--main-background-color'])
            localStorage.setItem('clapColor',this.confData['--btn-color'])
        
            r.style.setProperty('--main-background-color',this.confData['--main-background-color']);
            r.style.setProperty('--header-background-color',this.confData['--header-background-color']);
            r.style.setProperty('--login-modal-background-color',this.confData['--login-modal-background-color']);
            r.style.setProperty('--comments-background-color',this.confData['--comments-background-color']);
            r.style.setProperty('--btn-color',this.confData['--btn-color']);
            r.style.setProperty('--title-color',this.confData['--title-color']);
            r.style.setProperty('--text-color',this.confData['--text-color']);
            r.style.setProperty('--text-soft-color',this.confData['--text-soft-color']); 
            r.style.setProperty('--border-color',this.confData['--border-color']);
            r.style.setProperty('--header-text-color',this.confData['--header-text-color']); 
        
      }).catch(() =>{
        // console.log(e)
      })
        },
        checkParams(){
            let send = false
            for(let i = 0; i < this.config.length; i++) {
                for(let j = 0; j < this.oldConfig.length; j++){
                    if( (this.config[i].id === this.oldConfig[j].id) &&
                        this.config[i]['value'] !== this.oldConfig[j]['value']) { 
                        send = true
                    }
                }
            }

            if(this.send_login_logo || this.send_main_logo) {
                send = true
            }

            return send
        }
    }

}
</script>

<style lang="scss" scoped>
@import '../../assets/css/buttonStyle.scss';
.config{
   
    .wrapp:nth-of-type(odd){
        background-color: rgba(0, 0, 0, 0.2);
    }
    .wrapp{
        display: flex;
        width:100%;

        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        // padding: 5px;
        .div{
            max-width: 100%;
        }
        .wrapInput{
            display: flex;
            // flex-wrap: wrap;
            align-items: center;
            width: 831px;
            max-width: 100%;
            // overflow: auto;
           
            .title{
                width: 250px;

            }
            textarea,
            input{
                margin: 5px 0;
                height: 39.48px;
                border: 1px solid var(--border-color);
                // filter: drop-shadow(0px 1px 3px #DFDFE8);
                border-radius: 6px;
                outline: none;
                width: 550px;
                // width:68%;
                background: transparent;
                color: var(--text-color);

            }
            textarea {
                height: 150px;
            }
            .inputPicker{
                width: calc(550px - 40px);
            }
            .selectPicker {
                height: 39.48px;
                width: 39.48px;
                border: 1px solid var(--border-color);
                border-radius: 6px;
                position: relative;
                .fill{
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                .picker{
                    position:absolute;
                    top:36px;
                    right: 40px;
                    z-index:1;
                }
            }

        }
       
        .image{
            margin-right: auto;
            width: 831px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .info{
            margin-left: 10px;
            // margin-bottom: 10px;
            position: relative;
            width:fit-content;
        }

    }
    .wrappButton{
        background-color: var(--main-background-color);
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px ;
        position: sticky;
        bottom: 0;
        z-index: 2;

        .buttonSend,
        .buttonCancel {
            width:40%;
        }
        
    }

}
@media only screen and (max-width:1100px){
    .config{
        .wrapInput{
            // flex-direction:column;
            flex-wrap: wrap;
            height: fit-content;
            padding-top: 10px;
            padding-bottom: 5px;
            
        }
        .title{
            order:1
        }
        .info{
            margin-top: 3px;
            order: 2;
        }
        textarea,
            input{
                order:3;
            }
    }
}
</style>
