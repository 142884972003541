<template lang="html">
  <div class="wrappSingleBlog">
    <div class=""  id="singleBlog" v-if="blog">
      <progresBar v-if="getContent && contentHeight" :contentHeight="contentHeight" />
      <img id="main-image" ref="mainImage" v-if="blog && blog.image" 
      :src="baseURL + blog.image" :alt="blog.img_alt ? blog.img_alt : 'main-blog-image'">
      <!-- <img ref="mainImage" v-else> -->
      <img id="main-image" ref="mainImage" v-else-if="default_thumb" 
       :src="setImg(default_thumb)" />
      <img id="main-image" ref="mainImage" v-else 
          :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../assets/images/defaultThumb.png')):
            require('../assets/images/defaultThumb.png') "
      />
      <div class="single-blog-wrapper" ref="info">
        <div class="title left">
          {{blog.title}}
        </div>
        <div class="author left">
          By {{blog.author}}
        </div>
        <div class="left-single-blog-details left">
          <singleBlogDetails 
            @showmodal='showmodal' 
            v-if="showShare" 
            :blog="blog"
            @refresh="getBlogData"
          />
        </div>                              
        <div class="content-wrapper" >
          <div v-html="getContent" class="content left" id="previewId" >
              <!-- <widgetBright /> -->
          </div>
          <div class="right"  >
            <div style="position: sticky; top: 20px; z-index: 230;">
            <singleBlogDetails 
              v-if="showShare" 
              @showmodal='showmodal' 
              
              :blog="blog" 
              @refresh="getBlogData"
            />
            <div v-if="site_banner && site_banner !== '.'" v-html="site_banner"></div>
            <widgetBlack v-else />
            </div>

          </div>
        </div>
        <div class="share left">
          <div class="upper">
            <!-- <span>Share post</span> -->
            <div v-if="blog.previous && blog.next" >
              <span @click="openBlog(blog.next)" v-if="blog.next">{{$t('main.previous')}}</span>
              <div v-if="blog.previous && blog.next" class="line"></div>
              <span @click="openBlog(blog.previous)" v-if="blog.previous">{{$t('main.next')}}</span>
            </div>
          </div>
          <!-- <div class="lower">
            <img :src="require('@/assets/images/fb.svg')" alt="facebook icon">
            <img :src="require('@/assets/images/linkedin.svg')" alt="linkedin icon">
            <img :src="require('@/assets/images/instagram.svg')" alt="instagram icon">
          </div> -->
        </div>
      </div>
      <!-- end single-blog-wrapper -->
        <!-- <newsletter /> -->
      
      <div class="single-blog-wrapper commentsSingleBlog">
        <div class="wrappComments">
          <div class="button" @click="showComments">
          <div>{{$t('comment.comments')}} </div> 
            <span>({{ blog.comments_count }})</span>
            <img 
            :src="
            production ? 
            path_prefix.slice(0, -1).concat(require('../assets/images/loginArrow.svg')) : 
            require('../assets/images/loginArrow.svg')" 
            class="imgArr" :class="{open: readComment}" alt="">
          </div>
          <div class="comment" :class="{commentTR:readComment}">
            <!-- <div class="button" @click="leaveComment = !leaveComment">Leave Comment</div> -->
            <div class="labelCom">{{$t('main.addCom') }}</div>
            <wrappLeaveComment :blog="blog" />

            <!-- <div class="button" @click="readComment = !readComment">Read Comment</div> -->
              <wrappComments v-if="readComment" :blog="blog" @close="showComments"/>
          </div>
         </div>
         <!-- end wrappComments -->
        <homeBlogWrapper v-if="blog.id" :blogId="blog.id" :blogPage="true" />
      </div>
     </div>
     <spiner v-else-if="!blog && showSpiner"/>
    <div v-else class="not_found_blog">
      <div class="wrapp">
        <img v-if="blog_not_found" :src="setImg(blog_not_found)" alt="not found"/>
        <img v-else 
            :src="
            production ?
            path_prefix.slice(0, -1).concat(require('../assets/images/not_found_blog.svg')) : 
            require('../assets/images/not_found_blog.svg')
            "
            alt="not found"
          >
        <div class="text" v-html="$t('main.notBlogFound')"></div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { api, baseURL, baseURLimage } from '@/api'
import homeBlogWrapper from '@/components/blog/homeBlogWrapper'
import singleBlogDetails from '@/components/blog/singleBlogDetails'
import progresBar from '@/components/progresBar.vue'
import wrappLeaveComment from '@/components/comments/wrappLeaveComment.vue'
import wrappComments from '@/components/comments/wrappComments.vue'
import helperDate from '@/js/helperDate.js'
import widgetBlack from '../components/widgete/widgetBlack.vue'
import spiner from '../components/spiner.vue'
export default {
  name: 'singleBlog',
  components: {
    homeBlogWrapper,
    singleBlogDetails,
    progresBar,
    wrappLeaveComment,
    wrappComments,
    widgetBlack,
    spiner
    
  },
  data () {
    return {
      // blog: {
      //   title: this.$route.params.id
      // },
      numCom:12,
      blog: null,
      showShare: true,
      supportsPassive : false,
      showMobileNav:false,
      contentHeight:0,
      setDate: '',
      leaveComment: false,
      readComment: false,
      site_banner: null,
      showSpiner: false,
      logo: null,
      production: false,
      path_prefix: null,
      blog_not_found: null,
      default_thumb: null
      
    }
  },
   metaInfo () {
        return {
        title: 'Blog',
        titleTemplate:  this.blog ? this.blog.title : this.$route.params.id  + ' - %s' ,
        meta:[
           {name: 'robots', content: 'index,follow'} ,
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.blog ? this.blog.description: ''
                },
                {
                     vmid: 'og:description',
                    property: 'og:description',
                    content:  this.blog ? this.blog.description: ''
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.blog ? this.blog.title : this.$route.params.id 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                {
                    vmid: 'og:image',
                    property: 'og:image',
                    itemprop: 'image',
                    content:  (this.blog ? this.setLink(this.blog.image) : this.setLink(this.logo))
                }
            ]
        }
    },
  created () {
    let config = JSON.parse( localStorage.getItem('config'))
    this.logo = Object.hasOwnProperty.call(config,'login_logo') ? config.login_logo : null
    this.blog_not_found = Object.hasOwnProperty.call(config,'blog_not_found') ? config.blog_not_found : null
    this.default_thumb = Object.hasOwnProperty.call(config,'default_thumb') ? config.default_thumb : null
    // window.addEventListener("resize", this.resize)
    // this.resize()
    this.getBlogData()
    this.site_banner = JSON.parse( localStorage.getItem('config', this.confData))['site_banner']
     try {
      // window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
      //   get:  this.supportsPassive = true  
      // }));
    } catch(e){
      console.log(e)
    }

    this.production = process.env.NODE_ENV === 'production'
    this.path_prefix = window.__blog_path_prefix

  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
   mounted(){
       window.addEventListener("resize", this.resize)
    this.resize()
    //  console.log(this.$refs.info.clientHeight)
    // this.contentHeight = this.$refs.info.clientHeigh
  
  },
  computed: {
    baseURL () {
      return baseURL
    },
    getContent () {
      if (this.blog && this.blog.content) {
        return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
      }
      return ''
    }
  
  },
  methods: {
  async  getBlogData () {
    this.blog = null
    this.showSpiner = true
    window.scrollTo({top: 0, behavior: 'smooth'});
    // console.log(this.$route.params.id)
    this.readComment = false
      // this.blog.title = this.$route.params.id
      let params = {
        id: this.$route.params.id.startsWith("/blog/") ? this.$route.params.id : '/blog/'+this.$route.params.id,
        locale: this.$i18n.locale
      }
    await  api.getBlogData(params).then(res => {
        // setTimeout(() => {
          this.blog = res.data.data
          localStorage.setItem('available_locales',JSON.stringify( this.blog.available_locales))
          this.setDate = helperDate.formatDate(res.data.data.date)
          this.showSpiner = false
        // }, 4000);

        // this.setOGImg()
        setTimeout(() => {
          this.colorSpan()
        }, 500);
            
      }).catch(e => {
        this.showSpiner = false
        this.blog = null
        console.log(e);
      })
    },
    openBlog (slug) {
      // this.$router.push('/blog/' + slug)
      // this.$router.push({ name: 'blog', params: { lang: this.$i18n.locale } })
      this.$router.push({ path:this.$i18n.locale+'/blog/'+slug, name: 'singleBlog', params: { lang: this.$i18n.locale, id:slug } })
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    openToLocale (locale) {
      // console.log(locale)
      let available_locales =JSON.parse(localStorage.getItem('available_locales'))
      let object = available_locales.find(obj => obj.locale === locale);
      // let object = this.blog.available_locales.find(obj => obj.locale === locale);
      
      if(object) {
        if(this.$route.params.id === '/blog/'+ object.slug || this.$route.params.id ===  object.slug){
          this.getBlogData()
          return false
        }
        this.$router.push({ path:locale +'/blog/'+ object.slug, name: 'singleBlog', params: { lang: locale, id:object.slug } })
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    showmodal(e) {
      let wheelOpt = this.supportsPassive ? { passive: false } : false;
      let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if(e){
        // call this to Disable
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "hidden"
        window.addEventListener("click", this.preventDefault, { passive:false });
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.removeEventListener("mousedown", this.preventDefault, { passive:false });
        window.removeEventListener("click", this.preventDefault, { passive:false });
        document.body.style.overflow = "initial"
      }
    },
    preventDefault(e) {
      e.preventDefault()
        if (e.deltaY < 0) {
          //scroll wheel up
          console.log("up");
        }
        if (e.deltaY > 0) {
          //scroll wheel down
          console.log("down");
        }
    },
    preventDefaultForScrollKeys(e) {
      var keys = {37: 1, 38: 1, 39: 1, 40: 1}
      if (keys[e.keyCode]) {
           this.preventDefault(e)
           return false
      }
    },
    resize () {
      if (window.innerWidth <= 500) {
        this.showMobileNav = true
        if(this.blog)
          setTimeout(() => {
            this.contentHeight = this.$refs.info.clientHeight + 80 + this.$refs.mainImage.clientHeight
          }, 500);
      } else {
        this.showMobileNav = false
        if(this.blog)
         setTimeout(() => {
           this.contentHeight = this.$refs.info.clientHeight + 80 + this.$refs.mainImage.clientHeight  // 80 is navbar
         }, 500);
      }
    },
    showComments() {
      this.readComment= !this.readComment
      // ucitaj komentare sa beka kada bude bilo
    }, 
    colorSpan(){
      var getEl = document
        .getElementById("previewId")
        .getElementsByTagName("img"); // document.getElementsByTagName("span");
      for (let i = 0; i < getEl.length; i++) {
        getEl[i].style.maxWidth = 100+"%";
      }
    },
    setImg(link){
     // return baseURL.replace('/blog-template/api/', link)
     if(this.production)
         return link
     return baseURLimage + link

    },
    // setUrl(link) {
    //   console.log(window.location.host)
    //   if(window.__blog_path_prefix !== undefined) {
    //     return window.location.host.concat(window.__blog_path_prefix,'/blog-template/api/', link)
    //   }
    //     return baseURL.replace('/blog-template/api/', link)
    // },
    setLink(link){
      if(window.__blog_path_prefix !== undefined) {
        return window.location.origin.concat(window.__blog_path_prefix,'api/', link)
      }
        return 'https:'+baseURL + link
      
    },
  //   setOGImg(){
  //     let img = (this.blog ? this.setLink(this.blog.image) : this.setLink(this.logo))
  //     // var ogmetatag = document.createElement('meta');
  //     // ogmetatag.setAttribute('property', 'og:image');
  //     // ogmetatag.setAttribute('content', img);
  //     // document.head.appendChild(ogmetatag);
  //     // document.head.prepend(ogmetatag)

  //     var allMetaElements = document.getElementsByTagName('meta');
  //   for (let i=0; i<allMetaElements.length; i++) { 
  //     if (allMetaElements[i].getAttribute("name") == "image") { 
  //       allMetaElements[i].setAttribute('content', img); 
  //       break;
  //     } 
  //   }
  // }

  },
  watch: {
    '$route': function (newV, oldV) {
      
      if(newV.params.lang !== oldV.params.lang) {
         this.openToLocale(newV.params.lang)
         // return is to avoid calling the api twice
         return
      }
       this.showShare= false
        // console.log(this.$route.params.id)
        this.getBlogData()
          setTimeout(() => {
                  this.showShare= true
                  // this.colorSpan()
              }, 500)
    },
    blog:{
      handler(){
         setTimeout(() => {
          if(this.$refs.info?.clientHeight )
           this.contentHeight = this.$refs.info.clientHeight + 80 + this.$refs.mainImage.clientHeight  // 80 is navbar
         
        //  console.log('this.contentHeight - '+this.contentHeight)
        //  console.log('this.contentHeight - '+this.$refs.info.clientHeight)
        //  console.log('this.contentHeight - '+this.$refs.mainImage.clientHeight)
        }, 500);
         
      },
      deep:true

    }
  }
}
</script>

<style lang="scss" scoped>
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.3s ease;
// }

// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }
.wrappSingleBlog{
  position: relative;
  min-height: calc(100vh - 80px);
  width: 100%;
}
#main-image {
  width: 100%;
  height: auto;
  max-height: 520px;
  object-fit: cover;
}
 
.single-blog-wrapper {
  // min-height: calc(100vh - 150px);
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  // padding-top: 42px;
  padding-top: 11px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .right {
    width: calc( 100% - 735px );
    max-width: 406px;
    margin-left: auto;
    padding-top: 20px;

    .latest-title {
      margin-top: 40px;
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 24px;
      color: var(--text-color);
    }
    .latest {
      padding: 25px 20px;
      margin-bottom: 3px;
      background: var(--main-background-color);
      font-size: 18px;
      line-height: 26px;
      color: var(--text-color);
    }
  }
  .left {
    max-width: 735px;
    width: 100%;
  }
  .title {
    font-size: 40px;
    line-height: 50px;
    color: var(--title-color);
    margin-bottom: 20px;
  }
  .left-single-blog-details {
    display: none;
  }
  .content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .content {
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
    margin-bottom: 57px;
  }
  .author {
    font-size: 16px;
    line-height: 26px;
    color: var(--text-color);
    margin-bottom: 60px;
  }
  .share {
    span {
      font-size: 13px;
      line-height: 24px;
      color: var(--text-color);
    }
    .upper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 40px;
      box-sizing: border-box;
      margin-bottom: 91px;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        .line {
          width: 30px;
          height: 3px;
          background: var(--border-color);
          margin: 0 7px;
        }
        span {
          cursor: pointer;
          font-size: 12px;
          line-height: 24px;
          color: var(--text-color);
        }
      }
    }
    .lower {
      margin-top: 12px;
      img {
        height: 16px;
        margin-right: 30px;
        cursor: pointer;
      }
      img:last-child {
        margin-right: 0px;
      }
    }
   
  }

  
  
  @media only screen and (max-width: 1200px){
    .right {
      display: none;
    }
    .left {
      max-width: none;
      text-align: left;
    }
     .title {
      font-size: 35px;
      line-height: initial;
      // line-height: 30px;
      // color: var(--green-darker);
      margin-bottom: 20px;
    }
    .left-single-blog-details {
      display: block;
      margin-bottom: 40px;
    }
    .author {
      margin-bottom: 55px;
    }
    .share {
      .upper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

  }
   @media only screen and (max-width: 500px){
     .share {
      .upper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 51px;
      }
    }
   }
}
.commentsSingleBlog{
  display: block;
  transition: width 1s, height 1s, transform .5s;
 
  .wrappComments {
    max-width: 797px;
  }
  .labelCom{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
    color: var(--title-color);
  }
  .comment{
    transition:  min-height .5s linear,height .5s linear;
    height: 0px;
    min-height: 0px;
    overflow: hidden;
  }
  .commentTR{
    height: auto;
    min-height: 240px;
  }
  .button{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 45px;
    color: var(--title-color);
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;
    justify-content: flex-end;

    span{
      box-sizing: border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      // line-height: 23px;
      /* identical to box height, or 133% */

      letter-spacing: 0.01em;
      color: var(--text-soft-color);
      display: inline-block;
      margin-left: 9px;
      margin-right: 23px;
    }

    .imgArr{
      width: 12px;
      transition: transform .5s;
    }
    .open {
      transform: rotate(-180deg);
    }
  }
}


.not_found_blog{
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapp{
      max-width: 100%;
      position: relative;
      img {
        max-width: 100%;
      }
      .text{
        // position: absolute;
        // top: 50%;
        // bottom: 50%;
        // left: 32%;
        font-weight: 700;
        font-size: 36px;
        text-align: center;
        color:var(--btn-color);
        margin-top: 20px;
      }
    }
  }
</style>
<style lang="scss">
/* .content-wrapper .content p {
  margin: 0;
} */
#aSnipet{
   transition-duration: .1s;
}
#aSnipet:hover {
      // background-color:  var(--btn-hover)!important;
      filter: brightness(0.90) !important;
      // filter: drop-shadow(0px 1px 3px #DFDFE8);
}
.single-blog-wrapper {
  .baner {
      position: relative;
      // top: 25px;
      top: 56px
      // top : calc(198px + 56px); // 198px->blog details 56px->margin on design
    }
}
</style>
<style>
.slide-fade-enter-active {
  transition: all 1.2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
</style>
