<template>
    <div class="wrapForgot"> 
        <div class="enterPassword">
            <div class="title">Change password</div>
            <div class="txt">Please enter your new password and then type it again to confirm. Your password should be strong and unique, but easy for you to remember.</div>
            
            
                <input 
                    :class="passwordErr? 'basic inputErr':'basic'" 
                    v-model="password" 
                    type="password" 
                    placeholder="New password"   
                >
                <input 
                    :class="passwordErr? 'basic inputErr':'basic'"
                    v-model="retypePassword" 
                    type="password" 
                    placeholder="New password confirmation" 
                 >

                <div class="button" @click="checkForm">Confirm password change</div>
                <p class="errMsg">{{msg}}</p>
        </div>
    </div>
</template>

<script>
import { api } from '@/api'

export default {
    name: 'enterPassword',
    data(){
        return{
            password:null,
            retypePassword:null,
            passwordErr:false,
            msg:''
        }
    },
     mounted () {
        if (!this.tokenString) {
            this.$router.push({name: 'blog' })
        }
    },
    methods:{
            checkForm(){
            this.passwordErr = null
            this.msg =''

            if(this.password === '' || this.password.length <= 6  ){
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "The password must contain a minimum 7 characters.": "All fields must be entered correctly"
            }else if(this.password !== this.retypePassword) {
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "Passwords don’t match.": "All fields must be entered correctly"
            
            }else {

                let params={
                    token: this.tokenString,
                    password: this.password
                }
                api.recover(params).then(res => {
                    console.log(res)
                    this.$router.push({name: 'login' })
                }).catch(e =>{
                    this.msg = 'invalid-token'
                    console.log(e)
                })
            }
         },
    }, computed: {
        tokenString () {
        return this.$route.query.token
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/singUp.scss';
.wrapForgot { 
    display: flex;
    // width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    padding-top: 87px;}

.enterPassword{
    width:406px;
    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        /* identical to box height, or 143% */

        // display: flex;
        // align-items: center;
        text-align: center;
        width: 100%;

        /* black */

        color: var(--title-color);
    }
    .txt{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;

        /* black */

        color: var(--text-color);
        margin-top: 44px;
        margin-bottom:23px;

    }

    .button{
        margin-top:30px;
        width:100%;
        box-sizing:border-box;
        height: 50px;
        background: var(--btn-color);
        border-radius: 5px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        /* White */

        color: var(--main-background-color);
    }
    .button:hover{
        filter: brightness(.9);
    }
}
@media only screen and (max-width: 500px){
   .enterPassword{
     max-width: 335px;
     margin:0 20px;
    // padding: 0 20px;
    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        /* identical to box height, or 143% */

        // display: flex;
        // align-items: center;
        text-align: center;

        /* black */

        // color: var(--green-darker);
    }
    .txt{
        font-size: 15px;
        line-height: 24px;
        margin-top: 39px;
        margin-bottom:22px;

    }

    .button{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}

}
</style>