<template lang="html">
  <div class="html-snippets-wrapper">
    <div class="snippet-wrapper">
      <div class="title">
        Image full width
      </div>
      <textarea v-model="imgFullWidth"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Image float left
      </div>
      <textarea v-model="imgFloatLeft"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Image float Right
      </div>
      <textarea v-model="imgFloatRight"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Responsive iframe
      </div>
      <textarea v-model="responsiveIframe"></textarea>
    </div>
    <!-- <div class="snippet-wrapper">
      <div class="title">
        Responsive "Get a free car valuation in seconds" 
      </div>
      <textarea v-model="widget"></textarea>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'htmlSnippets',
  data () {
    return {
      imgFullWidth:
      `<div style="margin: 30px 0; margin-bottom: 20px;">
<img style="width: 100%;" src="img-link" ><div style="margin-top: -20px; font-size: 12px; color: #9498A0; line-height: 17px;">Deskripcija ispod slike</div>
</div>`,
      imgFloatLeft: `<img style="float: left; margin-bottom: 20px; margin-right: 20px; width: 50%;" src="img-link" >`,
      imgFloatRight: `<img style="float: right; margin-bottom: 20px; margin-left: 20px; width: 50%;" src="img-link" >`,
      responsiveIframe: `<div style="position: relative; width: 100%; height: 0; padding-bottom: 56.25%;">
      <iframe style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;" src="youtube-link" title="YouTube video player" frameborder="0" allow=""  allowfullscreen></iframe>
</div>`
  }
}
}
</script>

<style lang="scss" scoped>
.html-snippets-wrapper {
  text-align: left;
  .snippet-wrapper {
    margin-bottom: 20px;
    .title {
      color: var(--title-color);
      padding: 5px;
    }
    textarea {
      width: 100% !important;
      height: 100px !important;
      background-color: var(--main-background-color);
      color: var(--text-color);
    }
  }
}
</style>
