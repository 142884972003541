<template>
    <div class="info" v-if="explanation">
        <div class="mark"
                @mouseover="setHover = true"
                @mouseleave="setHover = false"
            >?</div>
        <div v-if="setHover" class="tooltip">{{ explanation }}</div>
    </div>
</template>

<script>
export default {
    props: {
        explanation: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            setHover: false
        }
    }

}
</script>
<style lang="scss" scoped>
.info {
    margin-left: 10px;
    // margin-bottom: 10px;
    position: relative;
    width: fit-content;
    cursor: pointer;

    img {
        width: 16px !important;
        height: auto;
        margin: 0;
    }
    .mark{
        border:1px solid var(--border-color);
        padding:1px 5px;
        border-radius: 50%;

    }
    .mark:hover{
        border: 1px solid var(--border-color);
        filter: drop-shadow(0px 1px 3px var(--border-color));
    }

    .tooltip {
        position: absolute;
        left: -20px;
        bottom: 25px;
        width: fit-content;
        border: 1px solid var(--border-color);
        filter: drop-shadow(0px 1px 3px var(--border-color));
        border-radius: 5px;
        padding: 5px;
        font-size: 12px;
        background-color: var(--main-background-color);
        color: var(--text-color)
    }

}
</style>