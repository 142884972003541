var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrappLogin",on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_c('span',{staticClass:"loginImg"},[_c('span',{staticClass:"imgRoundWrapp"},[_c('img',{staticClass:"imgRound",attrs:{"src":_vm.production ?
                _vm.path_prefix.slice(0, -1).concat(require('@/assets/images/icons/user.svg')) :
                require('@/assets/images/icons/user.svg')}})]),_c('span',{staticClass:"arrowRoundWrapp"},[_c('img',{attrs:{"src":_vm.production ?
            _vm.path_prefix.slice(0, -1).concat(_vm.getArrowGR) :
            _vm.getArrowGR}})])]),(_vm.showContent && (_vm.getLoged || _vm.setSid))?_c('div',{staticClass:"loginContent",staticStyle:{"width":"193px"}},[_c('router-link',{staticClass:"div",attrs:{"to":{ name: 'profile', params: { lang: _vm.$i18n.locale } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('profil.presonalInfo')))]),_c('span',[_c('img',{staticClass:"arrow",attrs:{"src":_vm.production ?
                _vm.path_prefix.slice(0, -1).concat(_vm.getArrow) :
                _vm.getArrow}})])]),(_vm.getuserData.usr_privilege === 1)?_c('router-link',{staticClass:"div",attrs:{"to":{ name: 'admin', params: { lang: _vm.$i18n.locale } }}},[_c('span',[_vm._v("Admin")]),_c('span',[_c('img',{staticClass:"arrow",attrs:{"src":_vm.production ?
                _vm.path_prefix.slice(0, -1).concat(_vm.getArrow) :
                _vm.getArrow}})])]):_vm._e(),_c('div',{staticClass:"div",on:{"click":_vm.logout}},[_c('span',[_vm._v(_vm._s(_vm.$t('log.logOut')))]),_c('span',[_c('img',{staticClass:"arrow",attrs:{"src":_vm.production ?
                _vm.path_prefix.slice(0, -1).concat(_vm.getArrow) :
                _vm.getArrow}})])])],1):(_vm.showContent)?_c('div',{staticClass:"loginContent"},[_c('router-link',{staticClass:"div",attrs:{"to":{ name: 'login', params: { lang: _vm.$i18n.locale } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('log.logIn')))]),_c('span',[_c('img',{staticClass:"arrow",attrs:{"src":_vm.production ?
                _vm.path_prefix.slice(0, -1).concat(_vm.getArrow) :
                _vm.getArrow}})])]),_c('router-link',{staticClass:"div",attrs:{"to":{ name: 'signin', params: { lang: _vm.$i18n.locale } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('sign.signUp')))]),_c('span',[_c('img',{staticClass:"arrow",attrs:{"src":_vm.production ?
                _vm.path_prefix.slice(0, -1).concat(_vm.getArrow) :
                _vm.getArrow}})])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }