<template>
  <div class="wrappLeaveComment">
    <div v-if="!getLoged" class="info">
      <input v-model="inputData.name.value" :class="inputData.name.error ? 'basic inputErr' : 'basic'" type="text"
        :placeholder='$t(inputData.name.name)' @keyup="inputData.name.error = false">
      <input v-model="inputData.email.value" :class="inputData.email.error ? 'basic inputErr' : 'basic'" type="email"
        :placeholder='$t(inputData.email.name)' @keyup="inputData.email.error = false">
    </div>
    <div v-else class="info">
      <div class="txtInfo">{{ $t('main.infoComm') }}:
        <div class="title">{{ getuserData.usr_name }}</div>
      </div>


    </div>
    <div class="wrappTextArea">
      <textarea v-model="inputData.content.value"
        :class="inputData.content.error ? 'basicTextarea textareaErr' : 'basicTextarea'"
        :placeholder='$t(inputData.content.name)' @keyup="inputData.content.error = false">
        </textarea>
      <div class="buttonFix" @click="checkForm">
        <img 
        :src="
        production ? 
        path_prefix.slice(0, -1).concat(require('../../assets/images/icons/right_white_arr.svg')) :
        require('../../assets/images/icons/right_white_arr.svg')" alt="">
      </div>
    </div>
    <div class="txt">{{ $t('comment.expTxt') }}

      <transition name="fade">
        <div v-if="msg" class="sucessMsg">
          {{ $t('comment.sucessMsg') }}
        </div>
      </transition>

    </div>



  </div>
</template>

<script>
import { api } from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'wrappLeaveComment',
  props: {
    blog: {
      default: null
    },
    com_id: {
      default: null
    }
  },
  data() {
    return {
      inputData: {
        content: {
          value: '', error: false, name: "log.comment"
        },
        name: {
          value: '', error: false, name: "sign.name"
        },
        email: {
          value: '', error: false, name: "log.email"
        }
      },
      msg: false,
      production: false,
      path_prefix: null


    }
  },
  created(){
    this.production = process.env.NODE_ENV === 'production'
    this.path_prefix = window.__blog_path_prefix

  },
  methods: {
    checkForm() {
      let check = false
      if (!this.getLoged) {
        for (let key in this.inputData) {
          if (
            this.inputData[key].value === ''
            || this.inputData[key].value.trim() === ''
          ) {
            this.inputData[key].error = true
            check = true
          }
        }
      } else {
        if (
            this.inputData['content'].value === ''
            || this.inputData['content'].value.trim() === ''
          ) {
            this.inputData['content'].error = true
            check = true
          }

      }

      if (check) {
        return
      } else {
        this.send()
      }

    },
   async send() {
      let params = {
        content: this.inputData.content.value,
        name: this.inputData.name.value,
        email: this.inputData.email.value
      }
      if (this.blog && this.blog.link) {
        params.slug = this.blog.link
      }
      if (this.com_id !== null) {
        params.com_id = this.com_id
      }
      if (this.$route.name === 'admin') {
        params.from_admin_panel = true
      }
      let chechReCaptcha = JSON.parse(localStorage.getItem('config'))
      if (Object.hasOwnProperty.call(chechReCaptcha, 'recaptcha_key_client')
      && chechReCaptcha.recaptcha_key_client !== null ) {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('comment').catch(() => {})
        params.token = token
      }
     
      api.postComent(params).then(res => {
        console.log(res)
        this.clearForm()
        this.msg = true
      }).catch(e => {
        // console.log(e.response.data.message)
        if (e.response.data.message === "content-length") {
          this.inputData.content.error = true
        }
        if (e.response.data.message === "invalid-email") {
          this.inputData.email.error = true
        }
        if (e.response.data.message === "name-length") {
          this.inputData.name.error = true
        }
      })
    },
    clearForm() {
      for (let key in this.inputData) {
        this.inputData[key].value = ''
        this.inputData[key].error = false
      }
    }
  },
  computed: {
    ...mapGetters(['getLoged', 'getuserData'])
  },
  watch: {
    msg: {
      handler(newM) {
        if (newM === true) {
          setTimeout(() => {
            this.$emit('close')
            this.msg = false
          }, 5000);
        }
      }
    }
  }


}
</script>

<style lang="scss" scoped>
@import '@/assets/css/singUp.scss';

.wrappLeaveComment {
  margin-bottom: 25px;
  max-width: 627px;

  .title {
    // font-size: 13px;
    // // line-height: 36px;
    // letter-spacing: 0.02em;
    // text-transform: uppercase;
    // color: #9498A0;
    // margin-bottom: 3px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: var(--title-color);
  }

  .txtInfo {
    padding-left: 20px;
    color: var(--text-soft-color);
  }

  .txt {
    font-size: 12px;
    // line-height: 36px;
    letter-spacing: 0.02em;
    color: var(--text-soft-color);
    // margin: 10px 0;
    text-align: center;
    min-height: 29px;

  }

  .sucessMsg {
    font-size: 12px;
    // line-height: 36px;
    letter-spacing: 0.02em;
    color: var(--btn-color);
    text-align: center;
  }

  .info {
    display: flex;
    justify-content: space-between;

  }

  .wrappTextArea {
    position: relative;


    .buttonFix {
      position: absolute;
      bottom: 18px;
      right: 21px;
      width: 30px;
      height: 30px;
      background-color: var(--btn-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  input,
  textarea {
    box-sizing: border-box;
    // width: 511px;
    width: 48%;
    height: 35px;
    border: 1px solid var(--border-color);
    border-radius: 25px;
    padding: 0 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;

    color: var(--text-darker);
  }

  textarea {
    font-family: 'Archivo';
    width: 100%;
    height: 141px;
    padding-top: 20px;
    padding-right: 52px;
  }

  .basicTextarea {
    margin-bottom: 0;
  }

  .textareaErr,
  .inputErr {
    border: 1px solid var(--err-color);
  }


  input::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;

    color: var(--text-lighter);
  }



}
</style>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>