<template>
    <div class="loginForgot">
        <enterEmail  v-if="step.emailShow" @checkstep="checkstep" />
        <success v-else-if="step.successShow" />
    </div>
</template>

<script>
import enterEmail from '@/components/forgotPassword/enterEmail'
import success from '@/components/forgotPassword/success'
export default {
    name: 'loginForgot',
    components: {
        enterEmail,
        success
    },
    data () {
        return {
            msg:'',
            step: {
                emailShow:true,
                successShow:false
            }

        }

    },
    mounted(){
    },
    methods: {
        checkstep(e) {
            console.log(e)
            for(let i in this.step) {
                this.step[i]=false
                if(i === e){
                    this.step[i]=true
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.loginForgot{
    display: flex;
    // width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    padding-top: 87px;
    
}
@media only screen and (max-width: 500px){
    .loginForgot{
        height: initial;
    }
}
</style>
