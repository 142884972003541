<template>
  <div class="wrappComments">
    <mainComment 
      v-for="(comm, index) in comments"
      :key="index"
      :comment="comm"
      :blog="blog"
    />
    <div class="option" v-if="showMore" @click="getBlogComent">{{$t('comment.seeMore')}}</div>
    <div class="option" v-else @click="showLess">{{$t('comment.seeLess')}}</div>
  </div>
</template>

<script>
import { api } from '@/api';
import mainComment from './mainComment.vue'
export default {
    name: 'wrappComments',
    props: {
      blog:{
        type: Object,
        default:null
      }

    },
    components: {
        mainComment
    },
    data(){
      return{
        offset: 0,
        count: 2,
        comments: [],
        showMore: true
          
      }
    },
    mounted(){
      this.getBlogComent()
    },
    methods: {
      getBlogComent() {
        let params= {
          count: this.count,
          offset: this.offset,
          blg_id: this.blog.id
        }
        api.getBlogComent(params).then(res => {
          // console.log(res.data.data)
          this.comments.push(...res.data.data)
          this.offset = this.comments.length
          if(res.data.count <= this.offset){
            this.showMore = false
          }
        }).catch(e => {
          console.log(e)
        })
      },
      showLess(){
        this.count = 4
        this.offset = 0
        this.comments = []
        // this.getBlogComent()
        this.$emit('close')

      }
    }

}
</script>

<style lang="scss" scoped>
.wrappComments{
  .option{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    color: var(--text-lighter);
    margin-bottom: 30px;
    cursor: pointer;
  }
    
}

</style>