<template lang="html">
  <div class="create-blog-wrapper">
    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.title')}}: ({{blog.title?blog.title.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.title">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.description')}}: ({{blog.description ? blog.description.length:0}} {{$t('admin.create.enteredChar')}} - 500 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="500" v-model="blog.description">
    </div>
    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.date')}}:</span>   <!--   (yyyy-mm-dd) -->
      <input type="date" v-model="blog.date" format="dd-mm-yyyy">
    </div>
    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.minutes')}}:</span>
      <input type="number" max="255" v-model="blog.readTime">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.author')}}: ({{blog.author ? blog.author.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.author">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.link')}}: ({{blog.link ? blog.link.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.link">
    </div>
<!-- thumb image -->
    <div v-if="show_thumb" class="input-wrapper">
        <span class="title">Thumb image:</span>
        <div class="current-image-wrapper">
          <img :src="show_thumb" alt="current-image" class="current-image">
          <div @click="removeImageThumb()" class="remove">
            remove
          </div>
        </div>
      </div>

     <div class="input-wrapper">
      <span class="title">{{$t('admin.create.imgSmall')}} ({{$t('admin.create.thumbShare')}} - 1.91:1 aspect ratio):</span>
      <input ref="thumb" type="file" accept=".png, .jpg, .jpeg" @change="setsmallImage($event)">
    </div>
     <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.thumbAlt')}}: ({{blog.thumb_alt ? blog.thumb_alt.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.thumb_alt">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.thumbName')}}: ({{blog.thumb_name ? blog.thumb_name.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.thumb_name">
    </div>
<!-- main image -->
    <div v-if="show_image" class="input-wrapper">
        <span class="title">Main image:</span>
        <div class="current-image-wrapper">
          <img :src="show_image" alt="current-image" class="current-image">
          <div @click="removeImage()" class="remove">
            remove
          </div>
        </div>
      </div>

    <div class="input-wrapper">
      <span class="title">{{$t('admin.create.imgMain')}}: (0 - 1.5mb)</span>
      <input id="image" type="file" accept=".png, .jpg, .jpeg" @change="setImage($event)">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.imgAlt')}}: ({{blog.img_alt ? blog.img_alt.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.img_alt">
    </div>
    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.imgName')}}: ({{blog.img_name ? blog.img_name.length:0}} {{$t('admin.create.enteredChar')}} - 255 {{$t('admin.create.maxChar')}})</span>
      <input type="text" maxlength="255" v-model="blog.img_name">
    </div>

<!--  -->
    <!-- <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.maxPoints')}}:</span>
      <input type="number" maxlength="255" v-model="blog.max_points">
    </div> -->

    <div class="input-wrapper">
          <span class="title">Categories: </span>
          <div class="setCheck">
            <div class="itemCheck" v-for="(caat, index) in categoriseApi" :key="index">
              <input v-if="caat.cat_deleted !=1" type="checkbox"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="checkedList" >
              <label v-if="caat.cat_deleted !=1" class='caat' :for="caat.cat_id">{{caat.cat_name}}</label>
            </div>
          </div>
        </div>

    <div class="localePickerAdmin">
       <LocalePicker @setlocal="setLocal"/>
    </div> 
    <dropdown  
      :defaultText="$t('main.status')"
      :selected="blog.status"
      :list="getBlogStatuses"
      @changeOption="blog.status = $event"
    />

<!--  -->

    <div class="input-wrapper">
      <span class="title"> {{$t('admin.create.content')}}: ({{60000 - blog.content.length }} {{$t('admin.create.canChar')}} )</span>
      <vue-editor v-model="blog.content"></vue-editor>
      <div class="preview" id="previewId">
        <div class="title">{{$t('admin.create.preview')}}:</div>
        <div v-html="getPreview" class="content">
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <div @click="resetPage" class="buttonCancel">
        {{$t('admin.create.reset')}}
      </div>
      <div @click="create" class="buttonSend">
        {{$t('admin.create.create')}}
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import { VueEditor } from "vue2-editor";
// import axios from 'axios'
import LocalePicker from '../LocalePickerForm.vue'
import dropdown from '../inputcomponents/drop.vue'
import { mapGetters } from 'vuex';
export default {
  name: 'createBlog',
  components: {
    VueEditor,
    LocalePicker,
    dropdown,
  },
  data () {
    return {
      blog: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
        thumb: null,
        thumb_name: null,
        thumb_alt: null,
        image: null,
        img_alt: null,
        img_name: null,
        type: 0,
        max_points: null,
        locale: null,
        status: null,

      },
      required: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
        locale: null,
        status: null
      },
       categoriseApi: null,
       checkedList:[],
       show_thumb: null,
       show_image: null

    }
  },
  mounted(){
    this.setCategory()
  },
  methods: {
    async setImage (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('image too big')
        document.querySelector('#image').value = null
        return
      }
      this.blog.image = e.target.files[0]
      this.show_image = await this.showImage(e.target.files[0])

    },
    async setsmallImage (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('thumb is too big')
        this.$refs.thumb.value = null
        return
      }
      this.blog.thumb = e.target.files[0]
      this.show_thumb = await this.showImage(e.target.files[0])

    },
    create () {
      if (!this.checkAllParams()) {
        return
      } else {
        let params = new FormData()
        for (var variable in this.blog) {
          // console.log(this.blog[variable]);
          if (this.blog[variable] !== null) {
            if(variable === 'status') {
              params.append(variable, this.blog[variable].id)
            }else {
              params.append(variable, this.blog[variable])
            }
          }
        }
        // params.append('locale', this.$i18n.locale)
        params.append('categories', JSON.stringify(this.checkedList))
        api.createBlog(params).then(() => {
          this.resetPage()
          alert('blog created successfuly')
        }).catch(e => {
        //  console.log();
          alert('error creating blog: ' + e.response.data.message)
        })
      }
    },
    validate(evt) {
      // for date
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === 'paste') {
          key = event.clipboardData.getData('text/plain');
      } else {
      // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
      var regex = /[0-9-]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    checkAllParams () {
      //  if(this.checkedList <= 0 ){
      //   alert('You must have at least one category selected!')
      //     return false
      // }
      for (var variable in this.required) {
        if (this.blog[variable] === null || this.blog[variable] === '') {
          if(variable === 'locale') {
            this.blog[variable] = this.$i18n.locale
          } else {
            alert(this.getName(variable) + ' is missing!')
            return false
          }
        }
      }
      return true
    },
    setLocal(local){
      // console.log(local);
      this.blog.locale = local
    },
    getName (variable) {
      if (variable === 'img_alt') {
        return 'image alt'
      }
      if (variable === 'img_name') {
        return 'image name'
      }
      if (variable === 'readTime') {
        return 'minutes to read'
      }
       if (variable === 'thumb') {
        return 'thumb image'
      }
      if (variable === 'thumb_name') {
        return 'thumb name'
      }
      if (variable === 'thumb_alt') {
        return 'thumb alt'
      }
      return variable
    },
    resetPage () {
      this.blog = {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
        image: null,
        img_alt: null,
        img_name: null,
        type: 0
      }
      document.getElementById('image').value= null;
      this.$refs.thumb.value = null
      this.checkedList=[]
    },
     async setCategory(){
      //  await axios.get("http://028x122.mars-t.mars-hosting.com/api/news-categories"
      //     ).then((res)=>{
      //        console.log(res.data.data)
      //        this.categoriseApi = res.data.data   
      //     }).catch((e)=>{
      //      console.log(e)
      //     })

     await api.getCategories().then(res => {
        // console.log(res.data.data)
        this.categoriseApi = res.data.data
      }).catch(() => {
        // console.log(e)
      })
    },
    showImage (input_file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort()
          reject(new DOMException("Problem parsing input file."))
        };

        reader.onload = () => {
          resolve(reader.result)
        }

        reader.readAsDataURL(input_file)
      })
    },
    removeImage () {
      // this.blog.delete_image = true
      this.blog.image = null
      this.show_image = null
      document.querySelector('#image').value = null

    },
    removeImageThumb () {
      // this.blog.delete_thumb = true
      this.blog.thumb = null
      this.show_thumb = null
      this.$refs.thumb.value = null
    },
    colorSpan(){
      var getEl = document
        .getElementById("previewId")
        .getElementsByTagName("img"); // document.getElementsByTagName("img");
      for (let i = 0; i < getEl.length; i++) {
        getEl[i].style.maxWidth = 100+"%";
      }
    }
  },
  watch: {
    'blog.content': {
      handler (){
        let date =  Date.now();
        setTimeout(() => {
          if (date + 100 <= Date.now()) {
            // console.log("da");
            this.colorSpan();
          } else {
            // console.log("ne");
          }
        }, 100);

      }
    }

  },
  computed: {
    ...mapGetters(['getBlogStatuses']),
    getPreview () {
      return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/adminBlogForm.scss';

</style>
<style lang="scss">
.localePickerAdmin{
  background: var(--main-background-color);
  width: 65px;
  height: 150px;
  color: #5A7E87;
  .picker-wrapper {
    height: fit-content;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    color: #5A7E87;
  .picker-title{
    color: #5A7E87;
  }
  }
}
</style>
