<template>
    <div class="wrappSearch">
        <input
            v-model="search" 
            type="text"
            :placeholder="$t('main.search')"
            @keyup="keyUp"
        />
        <img :src="
        production ?
        path_prefix.slice(0, -1).concat(require('../../assets/images/icons/search.svg')):
        require('../../assets/images/icons/search.svg')"/>
    </div>

</template>

<script>

export default {
    name: "search",
    data(){
        return{
            search: "",
            date: null,
            production: false,
            path_prefix: null

        }
    },
    props: ['reset'],
    mounted() {
        this.search=''
        this.production = process.env.NODE_ENV === 'production'
        this.path_prefix = window.__blog_path_prefix
    },
    methods: {
        keyUp(){
            // console.log(e)
            // console.log(Date.now())
            this.date=Date.now()

            setTimeout(() => {
            if(this.date +1000 <= Date.now()){
            // console.log('true - ' + this.search)
            this.$emit('search', this.search)
            }else{
                // console.log('ne - ' + this.search)
            }
            // console.log(this.date +' '+Date.now())
            
            }, 1000);
        },
    },
    watch: {
        reset: {
             handler () { 
                this.search = ''
             }

        }
    }
}

</script>

<style lang="scss" scoped>
.wrappSearch{
    // width: 511px;
    // height: 56px;
    // width: 211px;
    // height: 26px;

    max-width: 400px;
    width: 100%;
    height: 36px;
    position: relative;
    input {
        box-sizing: border-box;
        border: 1px solid var(--border-color);
        outline: none;
        border-radius: 25px;
        width:100%;
        height: 100%;
        padding: 0 10px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        font-size: 14px;
        line-height: 17px;

        color: var(--text-color);
        background-color: var(--main-background-color);
    }
    input ::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        font-size: 14px;
        line-height: 17px;

        color: var(--text-soft-color);

    }

    img{
        position: absolute;
        right: 10px;
        top: calc(50% - 6px);
        height: 13px;
    }
}

</style>