<template>
    <div class="loginComponent">
        <div class="wrapp_top">
        <div v-if="logo" class="logo">
            <img :src="setUrl(logo)" />
            <!-- <img src="../../assets/images/logo.svg" alt=""> -->
        </div>
        <div class="loginTitle">{{$t('log.loginP')}}</div>
    </div>
        <!-- 
        <div v-if="false" v-facebook-login-button="appId" class="buttonSocial">
            <span>{{$t('log.continueWith')}} Facebook</span>
            <span ><img 
                :src="require(widthPage?'@/assets/images/fsingin.svg':'@/assets/images/fsinginBright.svg')" />
             </span>
        </div>
        
        <div v-google-signin-button="clientId"  class="buttonSocial">
            <span>{{$t('log.continueWith')}} Google</span>
            <span>
                <img :src="require(widthPage? '@/assets/images/gsingin.svg':'@/assets/images/gsinginBright.svg')" />
            </span>
        </div>

        <div class="or">
            <div class="line"></div>
           {{$t('log.or')}}
            <div class="line"></div>
        </div> -->
        <label for="" class="title">{{$t('log.email') }}</label>
        <input v-model="email" class="basic" :placeholder="$t('log.email')" type="text"  @keyup.enter="focusEmail" autofocus>
        <label for="" class="title">{{$t('log.password')}}</label>
        <input v-model="password" class="basic" :placeholder="$t('log.password')" ref="usr_email" @keyup.enter="checkForm"  type="password"  >
        <!-- <forgotpass /> -->
        <!-- <rember :remember="remember" @rememberSend="remember=!remember" /> -->
        
        <div class="buttonSend" @click="checkForm">{{$t('log.logIn')}}</div>
        <!-- <div class="haveaccount">{{$t('log.accountYet')}} <router-link  :to="{ name: 'signin', params: { lang: $i18n.locale } }" class="orange">{{$t('sign.signUp')}}</router-link></div> -->
        <p v-if="msg" class="errMsg">{{$t(msg)}}</p> <p v-if="msgerr" class="errMsg">{{$t(msgerr)}}</p>   

    </div>
</template>

<script>
import helper from '@/js/helperMail'
// import rember from './rememberMe.vue'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import FacebookLoginButton from 'vue-facebook-login-button-directive'
import { api, baseURLimage } from '@/api'
// import forgotpass from './forgotpass.vue'
 // import eventBus from '@/js/eventBus.js'
export default {
    name: 'loginComponent',
     directives: {
        GoogleSignInButton,
        FacebookLoginButton

    },
    props: {
        msgerr:{
            required: false,
            default: null,
            type: String
        }
    },
    components: {
        // rember,
        // comment when the back supports the functionality
        // forgotpass
    },
    data () {
        return {
            email: '',
            password: '',
            remember: false,
            emailErr: false,
            passwordErr: false,
            msg: '',
            widthPage: null,
            // id app google
            clientId: '306827492643-at00dqjbklkflbi52fnd1ckrkthpahjj.apps.googleusercontent.com',
            // fb
            appId: 3821175311320178,
            logo: null
        }
    },
    created(){
        window.addEventListener("resize", this.resize)
        this.resize()
        // get logo 
        let config = JSON.parse(localStorage.getItem('config'))
        this.logo = Object.hasOwnProperty.call(config, 'login_logo') ? config.login_logo : null
    },
    methods: {
        // FACEBOOK
         OnFacebookAuthSuccess(idToken) {
            const params={
                token:idToken
            }
            
            api.loginFacebook(params).then(res => {
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="msg.wentWrong"
                console.log(e.message);
            })
            
        },
        OnFacebookAuthFail(error) {
             console.log(error)
            // if(error && error.error == 'popup_closed_by_user')
            //     this.msg= 'Popup closed by user'
            // else
                this.msg="msg.wentWrong"

        },
        // end facebook
        // GOOGLE
        OnGoogleAuthSuccess (idToken) {
            console.log(idToken)
            const params={
                token:idToken
            }
            
            api.loginGoogle(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="msg.wentWrong"
                console.log(e.message);
            })
            
        },
        OnGoogleAuthFail (error) {
            if(error && error.error == 'popup_closed_by_user')
                this.msg= 'msg.popupClosed'
            else
                this.msg="msg.wentWrong"
        },
        // end google
        getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                 console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        },
        checkForm(){

            let error = false
            this.clearErr()

            if(this.email === '' || this.email.trim().length == 0 || !helper.matchemail(this.email)){
                error= true
                this.emailErr = true
                this.msg = "msg.validEmail"
            }
            if(this.password === '' || this.password.length <= 5 ){
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "msg.passIncorrect":  "msg.fieldsEnteredCorrectly"
            }
            if(error) {
                return
            }else {
                let data = {
                    email:this.email,
                    password: this.password
                }
                this.$emit('login', data)
                this.clearData()
            }

        },
        clearErr () {
            this.emailErr = false
            this.passwordErr = false
            this.msg = ''
            this.$emit('clearmsgerr')
        },
        clearData () {
            this.email = ''
            this.password = ''
        },
        resize () {
            if (window.innerWidth <= 768) {
                this.widthPage = false
            } else {
                this.widthPage = true
            }
        },
        focusEmail () {
            this.$refs.usr_email.focus()
        },
        setUrl(link) {
            // return baseURL.replace('/blog-template/api/', link)
            return baseURLimage + link
        }

    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }


}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';

.loginComponent{
    background-color: var(--login-modal-background-color);
    // width: 406px;
    // height: fit-content;
    width: 384px;
    height: 510px;
    padding: 72px 47px;
    box-sizing: border-box;

    .wrapp_top{
        display: flex;
        flex-direction: column;
        .logo{
            margin: auto;
        }
        .loginTitle {
            font-style: normal;
            font-weight: 700;
            font-size: 23px;
            line-height: 20px;
            text-align: center;
            color: var(--text-color);
            white-space: nowrap;
            margin-top: 13px;
            margin-bottom: 57px;
        }
    }
        
       
    }
@media only screen and (max-width: 500px){
    .loginComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 334px;
        height: 711px;
        margin-bottom: 71px;

        .loginTitle {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 60px;
        }
       
    }
}

</style>
<style lang="scss">
    .loginComponent{
        .basic:last-of-type{
            margin-bottom: 10px;
        }
        .rember {
            margin-top: 24px;
            margin-bottom: 32px;
        }
    }
@media only screen and (max-width: 500px){
    .loginComponent{
        // .basic:last-of-type{
        //     margin-bottom: 10px;
        // }
        .rember {
            margin-top: 26px;
            margin-bottom: 28px;
        }
    }  
}

</style>