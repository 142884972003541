import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { api } from '@/api'
import blogList from '../views/blogList.vue'
import singleBlog from '@/views/singleBlog'
import admin from '@/views/admin.vue'
import login from '@/views/login.vue'
// import signin from '@/views/signin.vue'
import profile from '@/views/profile.vue'
import loginForgot from '@/views/loginForgot.vue'
import forgotPassword from '@/views/forgot-password.vue'
import i18n from '../i18n.js'
import PageNotFound from '@/views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/' + i18n.locale
  },
  {
    path: '/:lang',
    name: 'blog',
    component: blogList
  },
  {
    path: '/:lang/blog/:id',
    // path: '/blog/:id',
    name: 'singleBlog',
    component: singleBlog
  },
  // {
  //   path: '/:lang/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/:lang/admin',
    name: 'admin',
    meta: {
      superAdminRequired: true
    },
    component: admin
  },
  {
    path: '/:lang/login',
    name: 'login',
    meta: {
      loginRequired: true
    },
    component: login
  },
  // {
  //   path: '/:lang/signin',
  //   name: 'signin',
  //   meta: {
  //     loginRequired: true
  //   },
  //   component: signin
  // },
  {
    path: '/:lang/profile',
    name: 'profile',
    component: profile,
    meta: {
      sidRequired: true
    },
  },
  {
    path: '/:lang/loginForgot',
    name: 'loginForgot',
    component: loginForgot,
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/:lang/forgot-password',
    name: 'forgot-password',
    component: forgotPassword,
    // meta: {
    //   loginRequired: true
    // }
  },
  {
    path: '/:lang/not-found',
    name: 'NotFound',
    component: PageNotFound
  },
  {
    path: '*',
    redirect: `/${i18n.locale}/not-found`,
    name: 'NotFound',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  // console.log(from)

  if (to.params.lang) {
    for (let i = 0; i < store.state.langStore.length; i++) {
      if (to.params.lang === store.state.langStore[i]) {
        i18n.locale = to.params.lang
      }
      //  else {
      // console.log('to.params.lang = ' + to.params.lang)
      // }
    }
    // i18n.locale = to.params.lang
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.sidRequired) {
    if (localStorage.sid && store.state.loged) {
      return next()
    } else {
      return next('/' + i18n.locale)
    }
  } else if (to.meta.superAdminRequired) {
    api.checkSession().then(res => {
      // console.log('checkSession')
      // console.log(res)
      if (localStorage.sid && res.data.data.user_type === 1) {
        return next()
      } else {
        return next('/' + i18n.locale)
      }
      // }).catch(() => {
      //   return next('/')
    }).catch(() => {
      return next('/' + i18n.locale)
    })
  } else if (to.meta.loginRequired) {
    if (!localStorage.sid) {
      return next()
    } else {
      return next('/' + i18n.locale)
    }
  } else {
    return next()
  }
})

export default router
