<template>
  <div class="wrapProfilwData">
      <!--div v-for="(i,name, index) in profileInfo" :key="index">
          {{name+': '+i}}
      </div-->
    <div class="data">

            <div v-if="!showMiniTitle" class="titleProfile">{{$t('profil.presonalInfo')}}</div>
            <div v-if="!showMiniTitle" class="mailProfile"> {{editData.usr_email}} </div>
            
                <div class="label">{{$t('profil.name')}}</div>
                <div class="round">
                    <input v-if="!editName" v-model="editData.usr_name" class="input" type="text" readonly>
                    <input v-else v-model="editData.usr_name" ref="nameOld" :class="nameErr ?'input inputErr':'input'" type="text" >
                    <div v-if="!editName" class="editTxt" @click="editNameF">{{$t('profil.edit')}}</div>
                </div>

                <div class="label">{{$t('profil.password')}}</div>
                <div class="round">
                    <input v-if="!edit" v-model="placeholderPassword" class="input" type="password"  readonly />
                    <input v-else v-model="editData.old_password"  ref="passOld" :class="passwordErr ?'input inputErr':'input'"  :type="visibleOldPass ? 'text': 'password'" autocomplete="off" />

                    <div v-if="edit" class="cancelTxt" @click="edit = !edit">{{ $t('profil.cancel')}}</div>
                    <div v-if="!edit" class="editTxt"  @click="editPass">{{$t('profil.edit')}}</div>
                    <div v-else class="editTxt" @click="visibleOldPass = !visibleOldPass">
                        <img v-if="visibleOldPass" src="../../assets/images/icons/pass2.svg" alt="" />
                        <img v-else src="../../assets/images/icons/pass1.svg" alt="" />
                    </div>
                </div>

                <div v-if="edit">
                    <div class="label">{{$t('profil.newPassword')}}</div>
                    <div class="round">
                        <input :class="passwordErr ?'input inputErr':'input'" v-model="editData.usr_newPassword" :type="visibleNewPass ? 'text': 'password'" >
                        <div class="editTxt" @click="visibleNewPass = !visibleNewPass">
                            <img v-if="visibleNewPass" src="../../assets/images/icons/pass2.svg" alt="" />
                            <img v-else src="../../assets/images/icons/pass1.svg" alt="" />
                        </div>
                    </div>

                    <div class="label">{{$t('profil.reNewPassword')}}</div>
                    <div class="round">
                        <input :class="passwordErr ?'input inputErr':'input'" v-model="editData.usr_retypePassword" :type="visibleRePass ? 'text': 'password'" >
                        <div class="editTxt" @click="visibleRePass = !visibleRePass">
                            <img v-if="visibleRePass" src="../../assets/images/icons/pass2.svg" alt="" />
                            <img v-else src="../../assets/images/icons/pass1.svg" alt="" />
                        </div>
                    </div>
                </div>

            <div v-if="msg || msgerr" class="msg">{{msgerr ? $t(msgerr) : $t(msg)}}</div>
            <div class="roundButton">
                <div :class="disabled ? 'button disabled':'button'" @click="changeData">
                   {{ $t('profil.save')}}
                </div>
                <div class="noChanges">{{$t('log.or')}} <span class="bold" @click="returnRouter" > {{$t('profil.exitNoChanges')}} </span></div>
            </div>
    </div><!-- end data-->
      
    <!-- <div class="containerImg">
        <div v-if="showMiniTitle" class="titleProfile">{{$t('profil.presonalInfo')}}</div>
        <div v-if="showMiniTitle" class="mailProfile"> {{editData.usr_email}} </div>
        
        <wrappImg :oldImg="editData.image" @oldimg='oldimg'/>
    </div> -->
    <!-- end img-->
  </div>
</template>

<script>
// import wrappImg from './wrappImg.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'profileData',
    props: {
        profileInfo: {
            required: true,
            // default: false,
            type: Object
          },
          msgerr: {
              require: false,
              type: String
          }
    },
    components: {
        // wrappImg
    },
    data () {
        return{
            showMiniTitle: false,
            edit: false,
            editName: false,
            editData: {
                // usr_name: '',
                // usr_password: '',
                // usr_newPassword: '',
                // usr_retypePassword: '',
                // image: null
                usr_name: '',
                usr_active: 0,
                usr_email:'',
                old_password: '',
                usr_newPassword: '',
                usr_retypePassword: '',
                image: null

            },
            nameErr: false,
            emailErr: false,
            passwordErr: false,
            placeholderPassword:'password..',
            disabled: true,
            msg: '',
            visibleOldPass: false,
            visibleNewPass: false,
            visibleRePass: false

        }
    },
   
    created () {
        window.addEventListener("resize", this.resize)
        this.resize() 
        this.setData()
    },
    mounted(){
        
         this.setData()
    },
    methods: {
        changeImg(e){
            this.$emit('changedata',e)
        },
        changeData () {
            this.clearErr()
            if(this.disabled) return
            if(this.edit /* && this.editData.usr_newPassword &&  this.editData.usr_retypePassword */){
                if ( this.editData.old_password.length <= 0 || this.editData.usr_newPassword.length <= 0 || this.editData.usr_retypePassword.length <= 0) {
                    this.msg = 'msg.passFieldsEnteredCorrectly'
                    this.passwordErr = true
                    return
                }
                else if( this.edit && this.editData.usr_newPassword !== this.editData.usr_retypePassword) {
                    this.msg = 'msg.passMatch'
                    this.passwordErr = true
                    return
                }
                else if (this.edit && this.editData.usr_newPassword.length <= 6 || this.editData.usr_retypePassword <= 6) {
                    this.msg = 'msg.pass7char'
                    this.passwordErr = true
                    return
                }
            }else if(this.editName && this.editData.usr_name ==''){
                this.msg = 'msg.nameRequiredProfile'
                this.nameErr = true
                return
            }else if(this.editName && this.editData.usr_name.trim() ==''){
                this.msg = 'msg.nameRequiredProfile'
                this.nameErr = true
                return
             }
            if(this.editName || this.edit){
                this.$emit('changedata', this.editData)
                this.editName = false
                this.edit = false
            }else{
                this.msg ='msg.noDataChanges'
            }

        },
        setData () {
            // this.editData = this.profileInfo
                for (let property in this.editData) {
                    if(Object.hasOwnProperty.call(this.profileInfo, property))
                        this.editData[property]=this.profileInfo[property]
                }
            this.disabled = true
        },
        resize () {
            if (window.innerWidth <= 750) {
                this.showMiniTitle = true
            }
            else {
                this.showMiniTitle = false
            }
        },
        returnRouter() {
            this.setData()
            this.$router.go(-1)
        },
        oldimg (e) {
            this.editData.image=e

        },
        clearErr (){
            this.nameErr = false
            this.passwordErr = false
            this.msg = ''
            this.$emit('changemsg')
        },
         editPass () {
            this.edit= !this.edit
            // this.$refs.passOld.focus()
              this.$nextTick(() => this.$refs.passOld.focus())
        },
        editNameF () {
            this.editName= !this.editName
            this.$nextTick(() => this.$refs.nameOld.focus())
        }


    },
    watch: {
        editData: {
            handler () {
                if(this.edit || this.editName)
                 this.disabled = false
                 else this.disabled= true
            },
             deep: true,
             // immediate: true
        },
        // '$route': {
        //     handler(newVal, oldVla) {
        //         if(newVal&& oldVla)
        //         console.log(newVal.name +' - '+oldVla.name)
        //     },
        //     deep: true,
        //     immediate: true
        // },
        msgerr: {
            handler () {
                // this.editName = false
                // this.edit = false
                
            }
        }
    },
    computed: {
    ...mapGetters(['getLoged'])
    },
    destroyed() {
        window.removeEventListener("resize",this.resize)
    }
    

}
</script>

<style lang="scss" scoped>
.wrapProfilwData {
    display:flex;
    text-align: left;
    // background-color: var(--main-background-color);
    .data {
        width: 406px;
        margin-right: 127px;
        .titleProfile {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 50px;

            display: flex;
            align-items: center;
            color: var(--title-color);
        }
        .mailProfile {
            height: 24px;
            // left: 321px;
            // top: calc(50% - 24px/2 - 363.5px);

            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;

            display: flex;
            align-items: center;
            color: var(--text-color);

            margin-top: 6px;
            margin-bottom: 35px;

        }
        .label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            display: flex;
            align-items: center;

            color: var(--text-color);

            
        }
        .round {
            position: relative;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            // margin-bottom: 21px;
             margin-bottom: 20px;
            .input {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: var(--main-background-color);
                border: 1px solid var(--border-color);
                // filter: drop-shadow(0px 1px 3px var(--border-color));
                border-radius: 5px;
                outline: none;
                padding-left: 10px;

                // text 
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;

                color: var(--text-color);

            }
            .input:focus{
                background: var(--main-background-color);
                // border: 1px solid var(--text-lighter);
                filter: drop-shadow(0px 1px 3px var(--border-color));
                box-sizing: border-box;
                border-radius: 5px;
                color: var(--text-color);
            }
            .input:read-only{
                border: 1px solid var(--border-color);
                color: var(--text-soft-color);
                filter: none;
            }
            .inputErr{
                border: 1px solid var(--err-color);;
            }
            .editTxt{
                position: absolute;
                right:0;
                //top:50%;
                bottom: calc(50% - 10px);
                padding-right: 10px;
                max-height: 100%;
                cursor: pointer;
                //text
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.02em;
                // text-transform: uppercase;
                color: var(--text-soft-color);
                img{
                   height: 17px !important;
                   width: auto !important;
                //    opacity: 0.2;
                }
            }
            .cancelTxt{
                position: absolute;
                right:0;
                top:-35%;
                //bottom: calc(50% - 10px);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.02em;
                color: var(--text-soft-color);
                cursor: pointer;
            }
            .cancelTxt:hover {
                filter: brightness(0.9);
            }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            //   border: 1px solid green;
            -webkit-text-fill-color: var(--text-soft-color);
            -webkit-box-shadow: 0 0 0px 1000px var(--main-background-color) inset;
            transition: background-color 5000s ease-in-out 0s;
            // background-color: var(--main-background-color) !important;
        }
        

        .roundButton{
            margin-top:40px;
            margin-bottom: 42px;
            display: flex;
            align-items: center;
            .button {
                box-sizing: border-box;
                height: 50px;
                border-radius: 52px;
                background-color: var(--btn-color);
               
                padding: 13px 40px;
                color: var(--main-background-color);
                cursor: pointer;

                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;

                display: flex;
                align-items: center;
                text-align: center;
                margin-right: 20px;
            }
            .disabled {
                opacity: .4;
                filter: brightness(2);
                cursor: initial;

            }
            .noChanges{
                text-transform: capitalize;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                // display: flex;
                // align-items: center;
                // text-align: center;

                color: var(--text-soft-color);
            }
        }
    }
    .containerImg {
       
    }


}
.bold{
    cursor: pointer;
    font-weight: bold;
    text-transform: initial;
}
.msg{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: var(--btn-color);

}
.msgErr{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: var(--err-color);

}


// .wrapProfilwData {
//     .data {
//         .titleProfile {}
//         .mailProfile {}
//         .label {}
//         .round {
//             .input {}
//             .editTxt{}
//         }
//         .roundButton{
//             .button {   
//             }
//             .disabled {
//             }
//             .noChanges{  
//             }
//         }
//     }
//     .containerImg {
    
//         .wrappImg {
              
//         }
//     }
// }

@media only screen and (max-width: 767px){
     .wrapProfilwData {
      
      .data {
        width: 404px;
        margin-right: 79px;
        .titleProfile {
            font-size: 30px;
            line-height: 40px;
        }
        .mailProfile {}
        .label {}
        .round {
           margin-bottom: 22px; 
            .input {}
            .editTxt{}
        }
    }

}
}
@media only screen and (max-width: 750px){
     .wrapProfilwData {
        flex-direction: column-reverse;
        margin-bottom: 73px;
        width: 100%;
        

        .data {
            width: 343px;
            margin-right: 79px;
            .label {}
            .round {
            margin-bottom: 21px; 
                .input {}
                .editTxt{}
            }
            .roundButton{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
                .button {   
                    margin-bottom: 21px;
                    // min-width:120px;
                    height: 48px;
                    border-radius: 52px;
                    display: flex;
                    justify-content: center;
                    //padding-left: 0;

                }
                .disabled {
                    margin-bottom: 21px;
                }
                .noChanges{  
                }
            }

            
        }

        .containerImg {
            margin-bottom: 43px;
            .titleProfile {
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 50px;
                /* identical to box height, or 179% */


                color: #000000;

            }
            .mailProfile {
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;

                margin-bottom:32px;

            }
            .wrappImg {
                
            }
        }

    }

    .msg{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        // display: flex;
        // align-items: center;

        /* Orange primary */

        color: var(--btn-color);

        margin-bottom: 23px;


    }

}
@media only screen and (max-width: 500px){
     .wrapProfilwData {
        //  padding-bottom:90px;
        .noChanges{
            margin-bottom: 90px;
        }
     }
}

</style>