<template>
    <div>
     <div v-if="oldImage || image" class="input-wrapper">
        <span class="title">{{ mainTitle }}:</span>
          <div class="current-image-wrapper">
              <img 
                v-if="oldImage && image === null"
                :src="showOldImage(oldImage)" alt="current-image" class="current-image"
              >
              <img 
                v-if="image"
                :src="image" alt="current-image" class="current-image"
              >
              <div @click="deleteImg" class="remove">
                remove
              </div>
          </div>
      </div>
      <div class="input-wrapper">
        <span class="title"> {{ inputTitle }}:</span>
        <input ref="image" type="file" accept=".png, .jpg, .jpeg" @change="setImage($event)">
      </div>
    </div>
</template>
<script>
import { baseURLimage } from '../../api'
export default {
  name: "addImageBlog",
    props: {
      mainTitle:{
        type: String,
        default: "Image"
      },
      inputTitle:{
        type: String,
        default: "Image"
      },
      oldImage:{
        default:null
      },
      maxSize: {
        type: Number,
        default: 1500000
      },
      clear:{
      }
    },
    data(){
      return{
        image: null,
        production: false
      }
    },
    mounted(){
    this.production = process.env.NODE_ENV === 'production'
    },
    methods: {
      deleteImg() {
       console.log('s')
        this.image = null
        this.$refs.image.value = null
        this.$emit('delete')
      },
      async setImage(e){
        if (e.target.files && e.target.files[0].size >= this.maxSize) {
                alert('image is too big')
                this.$refs.image.value = null
                return
            }
            this.image = await this.showImage(e.target.files[0])
            this.$emit('setimg', e.target.files[0])
      },
      showImage(input_file) {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onerror = () => {
                    reader.abort()
                    reject(new DOMException("Problem parsing input file."))
                };

                reader.onload = () => {
                    resolve(reader.result)
                }

                reader.readAsDataURL(input_file)
            })
        },
        showOldImage (link) {
          if(this.production){
            if(link.startsWith('/')){
              return link
            } else {
              return '/'+link
            }
          }
          return baseURLimage + link

      }

    },
    watch: {
      clear: {
        handler(){
          this.$refs.image.value = null
          this.image = null
        }
      }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/adminBlogForm';
</style>