<template lang="html">
  <div class="outer-wrapper">
    <div class="admin-page-wrapper">
      <div class="left">
        <span @click="component = 'Create'" :class="{ active : component === 'Create'}" class="section">Create</span>
        <span @click="component = 'Edit'" :class="{ active : component === 'Edit'}" class="section">Edit</span>
        <span @click="component = 'Delete'" :class="{ active : component === 'Delete'}" class="section">Delete</span>
        <span @click="component = 'htmlSnippets'" :class="{ active : component === 'htmlSnippets'}" class="section">HTML snippets</span>
        <span @click="component = 'admnCategory'" :class="{ active : component === 'admnCategory'}" class="section">Category</span>
        <span @click="component = 'configComponent'" :class="{ active : component === 'configComponent'}" class="section">Config</span>
        <span @click="component = 'comments'" :class="{ active : component === 'comments'}" class="section">Comments</span>
        <div @click="logout" class="logout-btn section">
          logout
        </div>
      </div>
      <div class="right">
        <keep-alive>
          <component :is="activeComponent" @set="set" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import Create from '@/components/admin/create.vue'
import Edit from '@/components/admin/edit.vue'
import Delete from '@/components/admin/delete.vue'
import htmlSnippets from '@/components/admin/htmlSnippets.vue'
import admnCategory from '@/components/admin/categories.vue'
import configComponent from '@/components/admin/configComponent.vue'
import comments from '@/components/admin/comments.vue'
// import { api } from '@/api'
export default {
  name: 'admin',
  components: {
    Create,
    Edit,
    Delete,
    htmlSnippets,
    admnCategory,
    configComponent,
    comments,
    showMobileNav:false
  },
  data () {
    return {
      component: 'Create'
    }
  },
  created () {
      window.addEventListener("resize", this.resize)
      this.resize()
  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
  methods: {
    set () {
      this.component = 'configComponent'
    },
    resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    },
    logout () {
      /*
      api.logOut().then(() => {
        localStorage.removeItem('sid')
        localStorage.removeItem('userName')
        this.$store.commit('changeLoged', false)
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
        this.$router.push({ name: 'blog' })
      }).catch(() => {
        localStorage.removeItem('sid')
        localStorage.removeItem('userName')
        this.$store.commit('changeLoged', false)
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
      })
      */
        localStorage.removeItem('sid')
        localStorage.removeItem('userName')
        this.$store.commit('changeLoged', false)
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
        this.$router.push({ name: 'blog' })
    }
  },
  computed: {
    activeComponent () {
      return this.component
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-page-wrapper {
  min-height: calc( 100vh - 80px );
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 30px;

  .left {
    display: flex;
    flex-direction: column;
    width: 170px;

    .section {
      border-right: 3px solid var(--border-color);
      cursor: pointer;
      color: var(--text-soft-color);
      padding: 7px 0;
      cursor: pointer;
      transition-duration: .3s;
    }
    .section.active, .section:hover {
      color: var(--btn-color);
    }
    span {
      text-transform: uppercase;
    }
  }
  .right {
    padding-left: 20px;
    // width: 100%;
    width: calc(100% - 170px);
  }
}
</style>
