<template>
  <div class="progresBar">
      <div class="progress" :style="{ width: progres+'%'}"></div>
  </div>
</template>

<script>
export default {
    props:['contentHeight'],
    data(){
        return{
            height: 0,
            progres:0,
        }
    },
    mounted(){
        window.addEventListener('scroll', this.calcProgres)
        window.addEventListener("resize", this.calcProgres);
        this.calcProgres()
            
    },
    destroyed() {
        window.removeEventListener('scroll', this.calcProgres)
        window.removeEventListener("resize", this.calcProgres);
    },
    methods:{
        calcProgres(){
            // function for all content in page

            //   let height = (document.documentElement.scrollHeight - document.documentElement.clientHeight)
            //   let windowScrollPixels = (document.body.scrollTop || document.documentElement.scrollTop)
            //   let windowScrollPercents = (windowScrollPixels / height) * 100
            //   this.progres = windowScrollPercents 


            
            //  function is customized for part of content in page - set like props "contentHeight"

            // let helpHeight = document.documentElement.scrollHeight - this.contentHeight
            // let height = ((document.documentElement.scrollHeight- helpHeight) - document.documentElement.clientHeight)
            
            
            let height = (this.contentHeight - document.documentElement.clientHeight)
            let windowScrollPixels = (document.body.scrollTop || document.documentElement.scrollTop)
            let windowScrollPercents = (windowScrollPixels / height) * 100
            this.progres = windowScrollPercents 


        }
    }

}
</script>

<style lang="scss">
.progresBar{
    width: 100vw;
    height: 5px;
    background-color: transparent;
    z-index:9000;
    position: fixed;
    top:0;

}
.progress{
    box-sizing: border-box;
    height: 100%;
    width:0;
    background-color:var(--btn-color);
}


</style>